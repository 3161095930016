import {store} from './Store';
import {urls} from "./Loaders";
import {useOrderHistoryStore} from "./OrderHistory";
import {ChainOrderSource, Order, OrderStatus, Side} from "../types";
import {BigNumber} from "bignumber.js";
import {getSiweJwt, jwtsAreValid, signOutAndClearJWT} from "../lib/SiweClient";
import {ethers} from "ethers";

export function handleWebsocketUpdate(orderId: ethers.BigNumber,
                                      chainOrderSource: ChainOrderSource,
                                      instrumentId: number,
                                      side: Side,
                                      quantitySold: BigNumber,
                                      limitAmount: BigNumber,
                                      quantityObtained: BigNumber,
                                      timestamp: number,
                                      status: OrderStatus,
                                      placeTransactionId?: string,
                                      instructionId?: string,
                                      settleTransactionId?: string,
                                      refundTransactionId?: string,
                                      feePaid?: BigNumber,
                                      refundable?: boolean)
{
    const orderHistory = useOrderHistoryStore();

    const order: Order = {
        orderId,
        chainOrderSource,
        instrumentId,
        side,
        quantitySold,
        limitAmount,
        quantityObtained,
        timestamp,
        status,
        placeTransactionId: placeTransactionId == null ? instructionId : placeTransactionId,
        settleTransactionId,
        refundTransactionId,
        feePaid,
        refundable
    }

    if(store.lastPlacedOrder?.status && order?.status){
        if( store.lastPlacedOrder.status.toUpperCase() != order.status.toUpperCase()
            && store.lastPlacedOrder.placeTransactionId == order.placeTransactionId){
            store.lastPlacedOrder = order;
        }
    }

    console.log("Received order: " + JSON.stringify(order));

    orderHistory.upsertOrder(order);
}

export async function reloadHistoryFromApi()
{
    if (jwtsAreValid())
    {
        try
        {
            const chainInfo = store.getChainInfo();

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getSiweJwt()
            }
            const request: Request = new Request(urls.historyUrl(chainInfo.label), {
                method: "GET",
                headers: headers
            });
            const res: Response = await fetch(request);
            try
            {
                const orderHistory = useOrderHistoryStore();
                const fetchedOrders = (await res.json()).orders;
                console.log("Fetched orders", fetchedOrders);
                fetchedOrders.forEach((fetchedOrder) => {
                    const orderToStore: Order = {
                        orderId: ethers.BigNumber.from(fetchedOrder.orderId),
                        chainOrderSource: {
                            orderSource: fetchedOrder.orderSource,
                            smartContractAddress: "unknown"
                        },
                        instrumentId: fetchedOrder.instrumentId,
                        side: fetchedOrder.side,
                        quantitySold: new BigNumber(fetchedOrder.quantitySold),
                        limitAmount: new BigNumber(fetchedOrder.limitAmount),
                        quantityObtained: new BigNumber(fetchedOrder.quantityObtained),
                        feePaid: new BigNumber(fetchedOrder.feePaid),
                        timestamp: fetchedOrder.timestamp,
                        status: fetchedOrder.status,
                        placeTransactionId: fetchedOrder.placeTransactionId == null ? fetchedOrder.instructionId : fetchedOrder.placeTransactionId,
                        settleTransactionId: fetchedOrder.settleTransactionId
                    }
                    orderHistory.upsertOrder(orderToStore);
                });
            }
            catch (e)
            {
                console.log('loadHistory error', e);
                if (e.toString().indexOf('Unauthorized') > 0)
                {
                    signOutAndClearJWT("Unauthorised order history request");
                }
            }
        }
        catch (error)
        {
            store.tokenStatus = 'Error! Could not reach the API ' + error
            console.log('historyData error', store.tokenStatus);
        }
    }
}
