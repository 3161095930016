<script setup lang="ts">
import { store } from '../../store/Store';
import { onMounted, ref, watch } from "vue";
import BigNumber from "bignumber.js";
import {removeLeveragePosition} from "../../lib/LeveragePositions";


const props = defineProps<{
  title: string,
  token: string,
  extraClass: string,
  chainInstrumentId: number
}>();

const emit = defineEmits(['close'])
const amountToAction = ref(0);
const stakeToAddAction = ref(new BigNumber(0));

const onSelectedYesClick = (e) => {
  removeLeveragePosition(props.chainInstrumentId);
  closeModal();
}

const onSelectedNoClick = () => {
  closeModal();
}


const closeModal = () => {
  emit('close');
}

</script>

<template>

  <div class="alice-close-popup-container" id="topUpModalContainer">
    <div class="alice-popup-title">
      <span v-if="store.isWhitelisted" class="align-left">
        {{ "Close Position" }}
      </span>
      <span class="align-right">
        <o-icon pack="fas" icon="xmark" class="alice-popup-close-icon" @click="closeModal" />
      </span>
    </div>
    <div class="alice-popup-rule"></div>
    <div class="popup_content">
      <section>
        <div>
          <span>Are you sure you want to close this position for {{ props.token }}</span>
        </div>
      </section>
    </div>
    <div class="close_button_no">
      <button :id="'closeBtn-' + props.chainInstrumentId" @click="onSelectedYesClick" class="button_popup"
        :value="props.chainInstrumentId">{{ 'Yes' }}</button>
    </div>
    <div class="close_button_yes">
      <button :id="'closeBtn-' + props.chainInstrumentId" @click="onSelectedNoClick" class="button_popup"
        :value="props.chainInstrumentId">{{ 'No' }}</button>
    </div>
  </div>

</template>

<style scoped>

.alice-close-popup-container {
  width: 100%;
  min-width: 300px;
  min-height: fit-content;
  background-color: var(--popup-background-colour);
  text-align: center;
}

.close_button_no{
  float: right;
  margin:  0px 24px 10px 0px;
}

.close_button_yes {
  float: right;
}
</style>