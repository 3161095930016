<script setup lang="ts">

import {ref} from "vue";
import WithdrawPopup from "../elements/WithdrawPopup.vue";
import BigNumber from "bignumber.js";

const props = defineProps<{ title: string, extraClass: string, token: string, walletBalance: BigNumber, smartContractBalance: BigNumber }>();

const withdrawModalVisible = ref(false);

const openWithdrawPopup = () => {
  withdrawModalVisible.value = true;
};

</script>

<template>
  <div :class="['container-block','table_button_container',props.extraClass]">
    <div>
      <button :id="'withdrawBtn-' + props.token"   @click="openWithdrawPopup" class="button">{{ props.title }}</button>
    </div>
  </div>

  <o-modal v-model:active="withdrawModalVisible" class="alice-swap-popup-modal" width="300px" mobileBreakpoint="960px" scroll="clip">
    <WithdrawPopup @close="withdrawModalVisible = false" :token="props.token" :walletBalance="props.walletBalance" :smartContractBalance="props.smartContractBalance"></WithdrawPopup>
  </o-modal>
</template>

<style scoped>


</style>
