<script setup lang="ts">
import {store} from '../../store/Store';
import {onMounted , ref,watch} from "vue";
import {depositTokens} from "../../lib/Wallet";
import {validateInputForDecimal ,validatePasteForDecimal } from "../../utils/commonUtils";
import BigNumber from "bignumber.js";


const props = defineProps<{ token: string, walletBalance: BigNumber, smartContractBalance: BigNumber }>();

const emit = defineEmits(['close'])
const amountToAction = ref(0);
const walletBalanceBalanceAction = ref(new BigNumber(0));
const smartContractBalanceAction = ref(new BigNumber(0));

onMounted(() => {
  if( props.smartContractBalance && props.smartContractBalance.gte(0)){
    smartContractBalanceAction.value = props.smartContractBalance;
  }

  if(props.walletBalance && props.walletBalance.gte(0)){
    walletBalanceBalanceAction.value = props.walletBalance;
  }

  watch(() => props.smartContractBalance, () => {
    smartContractBalanceAction.value = props.smartContractBalance
  });

  watch(() => props.walletBalance, () => {
    walletBalanceBalanceAction.value = props.walletBalance
  });

});

const closeModal = () => {
  emit('close');
}

const deposit = () => {
  console.log("Deposit: ", props.token, " ", amountToAction.value);
  const currentState = store.web3Onboard.state.get()
  const wallet = currentState.wallets[0];
  const walletProvider = store.getWalletProvider(wallet);
  const signer = walletProvider.getUncheckedSigner();
  depositTokens(props.token, signer, new BigNumber(amountToAction.value), wallet.accounts[0].address);
  closeModal();
}

</script>

<template>
  <div class="alice-popup-container" id="depositModalContainer">
    <div class="alice-popup-title">
      <span v-if="store.isWhitelisted" class="align-left">
        {{ "Deposit" }}
      </span>
      <span v-else class="align-left">
        {{ "Unverified user" }}
      </span>
      <span class="align-right">
        <o-icon pack="fas" icon="xmark" class="alice-popup-close-icon" @click="closeModal"/>
      </span>
    </div>
    <div class="alice-popup-rule"></div>
    <div class="popup_content">
      <section>
      <div>
        <div v-if="store.isWhitelisted == false">
          <div class="parallel-text">
            You are not whitelisted.
          </div>
          <div class="parallel-text">
            Please get in touch and request a whitelist approval for your wallet.
          </div>
        </div>
        <div v-else>
          <div class="deposit_section_row">
          <div>Wallet Balance After Deposit </div>
          <div v-if="(walletBalanceBalanceAction.lt(new BigNumber(amountToAction)))" class="confirm-swap-token">
            <span id="walletTokenAmount">{{ walletBalanceBalanceAction.valueOf()}}&nbsp;</span>
            <span id="walletTokenName">{{ props.token }}</span>
          </div>
            <div v-else  class="confirm-swap-token">
              <span id="walletTokenAmount" v-if="new BigNumber(amountToAction).gte(0)">{{ walletBalanceBalanceAction.minus(new BigNumber(amountToAction)) }}&nbsp;</span>
              <span id="walletTokenAmount" v-else>{{ walletBalanceBalanceAction.valueOf() }}&nbsp;</span>
              <span id="walletTokenName">{{ props.token }}</span>
            </div>
        </div>
        <div class="deposit_section_row">
          <div >Smart Contract Balance After Deposit</div>
          <div v-if="(walletBalanceBalanceAction.lt(amountToAction)) && (smartContractBalanceAction.lt(0))" class="confirm-swap-token">
            <span id="contractTokenAmount">{{ 0 }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else-if="smartContractBalanceAction.lt(0)" class="confirm-swap-token">
            <span id="contractTokenAmount">{{ amountToAction }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else-if="walletBalanceBalanceAction.lt(amountToAction)" class="confirm-swap-token">
            <span id="contractTokenAmount"> {{ 0 }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else class="confirm-swap-token">
            <span id="contractTokenAmount" v-if="amountToAction==0">{{ smartContractBalanceAction.valueOf()}}&nbsp;</span>
            <span id="contractTokenAmount"  v-else-if ="amountToAction>0">{{ smartContractBalanceAction.plus(new BigNumber(amountToAction))}}&nbsp;</span>

            <span id="contractTokenName">{{ props.token }}</span>
          </div>
        </div>
        <div class="deposit_section_row input_amount_deposit">
          <o-input v-model="amountToAction" type="text" placeholder="Deposit Amount" id="amountEntryField"  @keydown="validateInputForDecimal" @paste="validatePasteForDecimal"></o-input> 
        </div>
      </div>

        <div v-if="store.isWhitelisted == false">
          <button id="closeModalButton" class="button_confirm_deposit" @click="closeModal">{{ "Close" }}</button>
        </div>
        <div v-else class="button_container">
          <div v-if="new BigNumber(amountToAction).gt(walletBalanceBalanceAction)">
            <button id="confirmAction" class="button_popup">{{ "Invalid Deposit Amount" }}</button>
          </div>
          <div v-else>
            <button id="confirmAction" class="button_popup" @click="deposit">{{ "Deposit" }}</button>
          </div>
        </div>
      
      </div>
      </section>
    </div>
  </div>
</template>

<style scoped>


.parallel-text {
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center
}

.confirm-swap-token {
  font-size: 18px;
}

.button_confirm_deposit {
  width: 200px;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 0.8em;
  font-weight: 400;
  font-family: inherit;
  border-radius: 4px;
  background-color: var(--button-background-colour);
  color: var(--button-text-light-colour);
}

.deposit_section_row{
  float: left; 
  margin-bottom: 10px;
}

.input_amount_deposit{
  width: 240px
}
</style>