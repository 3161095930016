<script setup lang="ts">
import HistoryHeader from './elements/HistoryHeader.vue';
import HistoryItem from './elements/HistoryItem.vue';
import HistoryTxIDs from './elements/HistoryTxIDs.vue';
import {useOrderHistoryStore} from "../store/OrderHistory";
import {computed, ref} from "vue";
import {
  filterByOrderSource,
  filterOrderSourceByDtw,
  formatOrderForUi,
  sortByTimestampThenOrderId
} from "../utils/OrderHistoryUtils";
import {refundOrder} from "../lib/Refund";
import HistoryInstructionIDs from "./elements/HistoryInstructionIDs.vue";
import BigNumber from "bignumber.js";
import Positions from './Positions.vue'

const props = defineProps<{ title: string, extraClass: string, useNarrowLayout: boolean, walletConnected: boolean, isDtw: boolean }>();
const activeTabPreFunded = ref(0);

const orderHistory = useOrderHistoryStore();

const formattedOrderHistory = computed(() => {
return orderHistory.orders
    .toSorted(sortByTimestampThenOrderId)
    .filter(filterByOrderSource())
    .filter(filterOrderSourceByDtw(props.isDtw))
    .reverse()
    .map((order: any, i: any) => formatOrderForUi(order, i));
})
</script>

<template>
  <div :class="['container-history-panel',extraClass]">
    <o-tabs v-model="activeTabPreFunded" type="boxed">
      <o-tab-item :value="0" label="Orders">
        <div class="">
        <o-table :data="formattedOrderHistory" paginated pagination-position="top" :per-page="(useNarrowLayout ? 1 : 3)" class="alice-history-table">
          <o-table-column v-if="props.isDtw" field="instruction_id" label="InstructionID" position="left" v-slot="props" class="alice-history-column">
            <HistoryInstructionIDs :instruction-id="props.row.full_tx_id" :truncated-instruction-id="props.row.place_tx" :position-index="props.row.positionIndex"/>
          </o-table-column>
          <o-table-column field="amount_sold" label="You Sold" position="left" v-slot="props" class="alice-history-column">
            <HistoryItem :token="props.row.soldSymbol" :index="props.row.positionIndex" :side="'sold'" :amount="props.row.sold"/>
          </o-table-column>
          <o-table-column field="amount_received" label="You Got" position="left" v-slot="props"
            class="alice-history-column">
          
            <section class="odocs-spaced" v-if="props.row.status == 'SETTLED'">
              <o-tooltip position="top" multiline>
                <HistoryItem :token="props.row.boughtSymbol" :index="props.row.positionIndex" :side="'got'"
                  :amount="props.row.received"></HistoryItem>
                  <template #content>
                  <div class="tooltipOrder">
                    <div>
                      <b>Amount Received: </b> {{ new BigNumber(props.row.feePaid).plus(new BigNumber(props.row.quantityObtained)) }}
                    </div>
                    <div>
                      <b>Fee Paid:</b>
                      {{ props.row.feePaid }}
                  </div>
                </div>
              </template>
              </o-tooltip>
          </section>
          <section v-else>
            <HistoryItem :token="props.row.boughtSymbol" :index="props.row.positionIndex" :side="'got'"
                  :amount="props.row.received"></HistoryItem>
          </section>
          </o-table-column>
          <o-table-column field="instrumentId" label="Limit Amount" numeric position="right" v-slot="props" class="alice-history-column">
            <span :id="'history-item-' + props.row.positionIndex + '-limit'">{{ props.row.limit }}</span>
          </o-table-column>
          <o-table-column field="buy_or_sell" label="Status" position="centered" v-slot="props" class="alice-history-column statusBtn">
            <o-button v-if="props.row.refundable" :id="'history-item-' + props.row.positionIndex + '-order-status'"
                      class="button_status"
                      tag="a"
                      @click="() => refundOrder(props.row)"
                      target="_blank"
                      variant="danger">REFUND
            </o-button>
            <o-button v-else :id="'history-item-' + props.row.positionIndex + '-order-status'"
                      class="button_status"
                      tag="a"
                      :href="props.row.tx_href"
                      target="_blank"
                      :variant="props.row.buttonType">{{ props.row.statusMessage }}
            </o-button>
          </o-table-column>
          <o-table-column field="timestamp" label="Time" position="left" v-slot="props" class="alice-history-column">
            {{ props.row.date }}
          </o-table-column>
          <o-table-column field="place_tx" label="TxID" position="right" v-slot="props" class="alice-history-column">
            <HistoryTxIDs :placeLink="props.row.place_tx_href" :placeTx="props.row.place_tx" :position-index="props.row.positionIndex"
                          :settleLink="props.row.settle_tx_href" :settleTx="props.row.settle_tx"/>
          </o-table-column>
        </o-table>
      <div v-if="walletConnected">
        <div v-if="formattedOrderHistory.length == 0">
          <div class="history-status">
            Awaiting your first trade
          </div>
        </div>
      </div>
      <div v-else>
        <div class="history-status">
          Please connect wallet to see transaction history
        </div>
      </div>
       </div>
      </o-tab-item>
      <o-tab-item :value="1" label="Positions">
        <Positions id="position-block" class="" title="" extra-class="" :walletConnected="props.walletConnected" :isDtw="props.isDtw"></Positions>
      </o-tab-item>
    </o-tabs>   
  </div>
</template>

<style scoped>
.container-history-panel {
  display: block;
}

.alice-history-header {
  position: absolute;
  margin-top: 10px;
}

.history-status {
  margin-top: 20px;
}

.tooltipOrder{
  width: fit-content;
  float: left;
  text-align: left;
}

</style>