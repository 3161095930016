// --------------------------------------------------------------------------------------------
// the following is used to map 'normal' to 'exotic' tokens 
// when otherwise their pricing would not be available

export const EXOTIC_SYMBOLS_MAP = {
    'ETHUSD': {
        symbol: 'wETHUSDC',
        front: 'wETH',
        back: 'USDC'
    },
    'BTCUSD': {
        symbol: 'wBTCUSDC',
        front: 'wBTC',
        back: 'USDC'
    },
    'USDCUSD': {
        fixprice: 1,
        symbol: 'USDCUSD',
        front: 'USDC',
        back: 'USD'
    }
}

export const EXOTIC_TOKENS_MAP = {
    'wETH': 'ETH',
    'wBTC': 'BTC',
    'WETH': 'ETH',
    'WBTC': 'BTC',
    'USDC': 'USD',
    'AUSA': 'AUS',
    'LINKA': 'LINK',
    'MATICA': 'MATIC',
    'XAUA': 'XAU',
    'CADA': 'CAD',
    'NZDA': 'NZD',
    'JPYA': 'JPY',
    'GBPA': 'GBP',
    'EURA': 'EUR',
    'USDA': 'USD',
    'SOLA': 'SOL',
    'PYTHA': 'PYTH',
}

export function mergeExoticPrices(priceMap, newPrices)
{
    for (let sym in EXOTIC_SYMBOLS_MAP)
    {
        if (sym in newPrices)
        {
            let spec = EXOTIC_SYMBOLS_MAP[sym];
            if ('fixprice' in spec)
            {
                priceMap[spec.symbol] = [spec.fixprice, spec.fixprice, spec.fixprice];
            }
            else
            {
                priceMap[spec.symbol] = newPrices[sym];
            }
        }
    }
}

export function mapExoticToken(token)
{
    let t = token; //.toUpperCase();
    if (t in EXOTIC_TOKENS_MAP)
    {
        return EXOTIC_TOKENS_MAP[t];
    }
    else
    {
        return token;
    }
}

export function mapExoticTokens(tokenArray)
{
    return tokenArray.map(mapExoticToken);
}

