<script setup lang="ts">
import Onboard from '../components/integrations/Onboard.vue';
import Swaps from '../components/Swaps.vue';
import Chart from '../components/Chart.vue';
import History from '../components/History.vue';
import OrderPositionNarrowLayout from '../components/OrderPositionNarrowLayout.vue';
import NotificationMessage from '../components/NotificationMessage.vue';
import {store} from '../store/Store';
import {onMounted, ref, watch} from "vue";
import Logo from "../components/Logo.vue";
import Balances from "../components/Balances.vue";
import SupportedChains from '../components/SupportedChains.vue';
import PrefundedNarrowLayout from '../components/PrefundedNarrowLayout.vue';


const activeTab = ref(0);
const activeTabPreFunded = ref(0);

import image from "../assets/alice-transparent.png"

const imageData = image;


onMounted(() => {
  var lsActiveTab = localStorage.getItem("activeTab");
  if (lsActiveTab) {
    activeTab.value = parseInt(lsActiveTab);
  }
  watch(activeTab, async (newActiveTabWide, oldActiveTabWide) => {
    if (newActiveTabWide != oldActiveTabWide) {
      localStorage.setItem("activeTab", activeTab.value?.toString());
    }
  });
  if (store.backendEnv !== "dev")
  {
    let statusPageIoScript = document.createElement('script');
    statusPageIoScript.setAttribute('src', 'https://projectalice.statuspage.io/embed/script.js');
    document.head.appendChild(statusPageIoScript);
  }
});

</script>

<template>
  <div v-if="!(store.walletIsConnected && store.useNarrowLayout)">
    <Logo class="logo-block"/>
  </div>
  <SupportedChains></SupportedChains>
  <Onboard class="sign-in-block"/>
  <section v-if="store.useNarrowLayout">
    <o-tabs v-model="activeTab" type="toggle" position="centered">
      <o-tab-item :value="0" override label="Instant" icon="arrow-right-arrow-left">  
        <div class="card alice-app-narrow-container">
          <NotificationMessage></NotificationMessage>
          <Swaps id="swaps-block" class="alice-ticket swaps-block" extra-class="" title="" v-bind:is-dtw="false"/>
        </div>
      </o-tab-item>
      <o-tab-item :value="1" override label="Prefunded" icon="arrow-right-arrow-left">
        <div class="card alice-app-narrow-container">
          <PrefundedNarrowLayout extra-class="" title="" :isDtw="true"></PrefundedNarrowLayout>
        </div>
      </o-tab-item>
      <o-tab-item :value="2" override label="Chart" icon="chart-simple">
        <div class="card alice-app-container">
          <Chart id="chart-block-mobile" type="mobile" class="alice-chart-container chart-block"/>
        </div>
      </o-tab-item>
      <o-tab-item :value="3" override label="Orders" icon="clock">
        <OrderPositionNarrowLayout id="history-block" class="" title="Orders" :use-narrow-layout="store.useNarrowLayout"
                   :wallet-connected="store.walletIsConnected" extra-class="" v-bind:is-dtw="undefined" />
      </o-tab-item>
    </o-tabs>
  </section>
  <section v-else>
    <o-tabs v-model="activeTab" type="boxed" position="centered">
      <o-tab-item id="instant-trade-page" :value="0" label="Instant Trade" icon="arrow-right-arrow-left">
        <div class="card alice-app-container alice-divider">
          <div class="alice-app-row">
            <Chart id="chart-block-instant" type="instant" class="alice-chart-container chart-block" />
            <Swaps id="swaps-block" class="alice-ticket swaps-block" extra-class="" title="" v-bind:is-dtw="false" />
          </div>
          <div class="">
            <History id="history-block" class="alice-history-container history-block" title="Orders"
              :use-narrow-layout="store.useNarrowLayout"  :wallet-connected="store.walletIsConnected" extra-class="" v-bind:is-dtw="false"/>
          </div>
        </div>
      </o-tab-item>
      <o-tab-item id="prefunded-trade-page" :value="1" label="Prefunded Trade" icon="arrow-right-arrow-left">
        <div class="card alice-app-container alice-divider">
          <div class="alice-app-row">
            <o-tabs v-model="activeTabPreFunded" type="boxed">
              <o-tab-item :value="0" label="Trade">
                <div class="alice-app-row">
                  <Chart id="chart-block-prefunded" type="prefunded" class="alice-chart-container chart-block" />
                  <Swaps id="swaps-block" class="alice-ticket swaps-block" extra-class="" title=""
                    v-bind:is-dtw="true"/>
                </div>
                <div class="alice-app-row">
                  <History id="history-block" class="alice-history-container history-block" title="Orders"
                    :use-narrow-layout="store.useNarrowLayout" :wallet-connected="store.walletIsConnected"
                    extra-class="" v-bind:is-dtw="true" />
                </div>
              </o-tab-item>
              <o-tab-item :value="1" label="Balances">
                <div class="alice-app-row ">
                 
                  <Balances id="balance-block" class="alice-balance-container balance-block" title="Balances"
                    :use-narrow-layout="store.useNarrowLayout" :wallet-connected="store.walletIsConnected"
                    extra-class="" />
                </div>
              </o-tab-item>
            </o-tabs>
          </div>
        </div>
      </o-tab-item>
      <o-tab-item id="about-page" :value="2" label="About" icon="circle-info">
        <div class="card alice-app-container alice-divider">
          <div class="alice-app-row">
            <div id="about-block" class="alice-history-container history-block">
              <img :src="imageData" role="img" alt="Alice Logo" width="200" />
              <br>
              Centralised Liquidity, Decentralised Security.<br/><br/> Why choose between good execution quality and trustless, secure trading?
              <br/>
              Worry no longer, access leading institutional centralised liquidity without counterparty risk.
            </div>
          </div>
        </div>
      </o-tab-item>
    </o-tabs>
  </section>
</template>