<script setup lang="ts">
import { onMounted, ref } from "vue";
import Balance from "./elements/Balance.vue";
import DepositButton from "./elements/DepositButton.vue";
import WithdrawButton from "./elements/WithdrawButton.vue";
import HistoryHeader from './elements/HistoryHeader.vue';

const props = defineProps<{ title: string, extraClass: string, useNarrowLayout: boolean, walletConnected: boolean, isDtw: boolean, prefundedBalanceList: any }>();

onMounted(() => {

});

</script>

<template>
  <div>
  <div> 
    <HistoryHeader title="Prefund your wallet" class="alice-history-header"></HistoryHeader>
  </div>
  <div class="alice-ticket-content">
    <div v-for="(row, i) in props.prefundedBalanceList" :key="i" class="card"
      :id="'order-history-entry-' + row.positionIndex">
      <div class="card-header">
        <div class="card-header-content">
          <img v-bind:src="'/crypto-icons/' + row.instrumentId.toLowerCase() + '.svg'" class="card-header-img-left" />
        </div>
        <div class="card-header-content-text"> {{ row.instrumentId }}</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="card-row-col-1">
            <label>
              Wallet Balance:
            </label>
            <Balance title="" :token="row.instrumentId" :amount="row.tokenBalance" which="front"
              extraClass="font-weight-bold"></Balance>
          </div>
          <div class="card-row-col-2">
            <DepositButton title="Deposit" extraClass="card-body-settled" :token="row.instrumentId"
              :walletBalance="row.tokenBalance" :smartContractBalance="row.depositedBalance"></DepositButton>
          </div>
        </div>
        <div class="row">
          <div class="card-row-col-1">
            <label>SC Balances:</label>
            <Balance title="" :token="row.instrumentId" :amount="row.depositedBalance" which="front"
            extraClass="font-weight-bold"></Balance>
          </div>
          <div class="card-row-col-2">
            <WithdrawButton title="Withdraw" extra-class="" :token="row.instrumentId"
              :walletBalance="row.tokenBalance" :smartContractBalance="row.depositedBalance"></WithdrawButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
@media (max-width: 959px) {

  .alice-history-header {
    padding: 6px 0px 2px 0px;
  }

  .alice-ticket-content {
    margin-top: 2px;
    height: 68dvh;
    overflow: auto;
    position: relative;
  }

  .card {
    width: 100%;
    margin: 2.8px 0px 0px 0px;
    border-radius: 4px;
    border: solid 2px #eaeaea;
    background-color: var(--buttonbar-background-colour);
  }

  .card-header {
    height: 2.2rem;
    display: flex;
    border-bottom: solid 1px #eaeaea;
  }

  .card-header-img-left {
    padding: 4px 2px 9px 6px;
    width: 30px;
  }

  .card-header-content-text {
    padding: 4px 3px 0px 6px;
    font-size: 14px;
  }

  .card-body {
    width: 100%;
    flex-grow: 0;
    padding: 0px 3px 28px 4px;
    background-color: var(--card-background-colour);
  }

  .row {
    margin: 5px 2px 2px 5px;
    height: 6.5dvh;
    font-size: 14px;
  }

  .card-row-col-1 {
    width: 50%;
    padding-top: 10px;
    float: left;
    text-align: left;
  }

  .card-row-col-2 {
    width: 50%;
    padding-top: 10px;
    float: right;
    text-align: right;
  }

  .card-body-button {
    height: 24px;
    float: right;
    flex-grow: 0;
    margin: 2px 0 0px 0px;
  }
}
</style>