<script setup lang="ts">
import {computed} from "vue";
import BigNumber from "bignumber.js";

const props = defineProps<{ title: string, extraClass: string, amount: BigNumber, token: string, which: string }>();

const formattedAmount = computed(() => {
  if (props.amount &&  props.amount.gt(0))
  {
    return props.amount.toFixed(5, BigNumber.ROUND_FLOOR);
  }
  else
  {
    return 0;
  }
})
</script>

<template>
  <div :class="['container-block','container-balance',props.extraClass]">
    <span v-if="props.title">{{ props.title }}&nbsp;</span>
    <span :id="'wallet-balance-' + props.which">{{ formattedAmount }}&nbsp;</span>
    <span :id="'wallet-currency-' + props.which" v-if="props.token">{{ props.token }}</span>
  </div>
</template>

<style scoped>
.container-balance {
  height: 19px;
  margin: 0 19px 0 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  text-align: left;
  color: var(--balance-text-colour);
}
</style>