<script setup lang="ts">
import { ref } from "vue";
import ClosePopup from "../elements/ClosePopup.vue";
import BigNumber from "bignumber.js";

const props = defineProps<{ title: string, extraClass: string, token: string, chainInstrumentId: number }>();


const topUpModalVisible = ref(false);

const openTopUpPopup = () => {
  topUpModalVisible.value = true;
};

</script>
<template>
  <div :class="['container-block', props.extraClass]">
    <div>
      <button :id="'topUpBtn-' + props.token" @click="openTopUpPopup" class="button o-btn--danger">{{ props.title }}</button>
    </div>
  </div>
  <o-modal v-model:active="topUpModalVisible" class="alice-swap-popup-modal" width="300px" mobileBreakpoint="960px"
    scroll="clip">
    <ClosePopup v-if="topUpModalVisible" @close="topUpModalVisible = false" :title="props.title" extra-class=""
      :token="props.token" :chain-instrument-id="props.chainInstrumentId"></ClosePopup>
  </o-modal>
</template>

<style scoped></style>
