/**
 *
 * defines a class to handle a transaction
 *
 * - initiates the trade
 * - watches for notifications
 * - sends state to the UI for display
 * - manages the state machine for all this
 *
 */

import {Contract, ethers, utils} from 'ethers';
import {store} from '../store/Store';
import {wsloaders} from '../store/Websocket';
import {ORDER_SOURCE_FROM_CHAIN_ID, setAllowance} from '../lib/Wallet';
import {handleWebsocketUpdate} from "../store/OrderHistoryManager";
import type {Order} from "../types";
import {useOrderHistoryStore} from "../store/OrderHistory";
import {v4 as uuidv4} from 'uuid';
import {OrderRequest, sendOrderRequest} from "./RestApiClient";
import BigNumber from "bignumber.js";
import {addLeveragePosition} from "./LeveragePositions";


type T_TRANSACTION_STATES = 'NEW' | 'PENDING' | 'SETTLED' | 'CANCELLED';

const NATIVE_ETH = "0x0000000000000000000000000000000000000000";

export class Transaction
{
    sellToken: string;
    buyToken: string;
    instrumentId: number;
    isBuyOrder: boolean;
    quantityToSell: BigNumber;
    limitAmountToGet: BigNumber;
    state: T_TRANSACTION_STATES;

    constructor(sellToken: string, buyToken: string, instrumentId: number, isBuyOrder: boolean, quantityToSell: BigNumber, limitAmountToGet?: BigNumber)
    {
        this.sellToken = sellToken;
        this.buyToken = buyToken;
        this.instrumentId = instrumentId;
        this.isBuyOrder = isBuyOrder;
        this.quantityToSell = quantityToSell;
        this.limitAmountToGet = limitAmountToGet || new BigNumber(0);
        this.state = 'NEW';
    }

    async send()
    {
        const currentState = store.web3Onboard.state.get()
        const wallets = currentState.wallets;
        const chainInfo = store.getChainInfo();
        if (wallets.length > 0 && chainInfo)
        {
            const wallet = wallets[0];
            const walletProvider = store.getWalletProvider(wallet);
            try
            {
                const sc: Contract = new Contract(chainInfo.smartContractAddress, chainInfo.abi, walletProvider.getUncheckedSigner());
                const amount: ethers.BigNumber = utils.parseUnits(this.quantityToSell.toFixed(store.tokenDPs[this.sellToken]), store.tokenDPs[this.sellToken]);
                const limit: ethers.BigNumber = utils.parseUnits(this.limitAmountToGet.toFixed(store.tokenDPs[this.buyToken]), store.tokenDPs[this.buyToken]);

                const timestampOfSubmission: number = (new Date()).getTime();
                console.log('>> will place order @', timestampOfSubmission, this.instrumentId, store.tokenDPs[this.sellToken], this.sellToken, this.buyToken, this.isBuyOrder, this.quantityToSell, '=',
                    amount, this.limitAmountToGet, '=', limit);

                const result = store.tokenAddresses[this.sellToken] === NATIVE_ETH
                    ? await sc.placeOrder(this.instrumentId, this.isBuyOrder, amount, limit, {value: amount})
                    : await sc.placeOrder(this.instrumentId, this.isBuyOrder, amount, limit);

                const orderStore = useOrderHistoryStore();

                const pendingOrder: Order = {
                    instrumentId: this.instrumentId,
                    placeTransactionId: result.hash,
                    quantitySold: this.quantityToSell,
                    timestamp: +Date.now(),
                    limitAmount: this.limitAmountToGet,
                    side: this.isBuyOrder ? "BUY" : "SELL",
                    status: "PENDING",
                    refundable: false,
                    chainOrderSource: {
                        orderSource: chainInfo.label,
                        smartContractAddress: chainInfo.smartContractAddress
                    },
                    quantityObtained: new BigNumber(0),
                    feePaid: new BigNumber(0)
                }

                store.lastPlacedOrder = pendingOrder;
                orderStore.upsertOrder(pendingOrder);

                result.wait(1).then(resultComplete => {
                    let events: Array<any> = resultComplete.events;
                    let orderId;
                    let timeStamp: number;
                    for (const event of events)
                    {
                        if (event.event == "OrderPlaced")
                        {
                            orderId = event.args[0];
                            timeStamp = event.args[6];
                            break;
                        }
                    }

                    const txID = result.hash;
                    this.state = 'PENDING';
                    wsloaders.sendMessage({
                        type: "ORDER_UPDATES",
                        orderSource: chainInfo.label,
                        txId: txID
                    });

                    handleWebsocketUpdate(orderId, {orderSource: chainInfo.label, smartContractAddress: chainInfo.smartContractAddress},
                        this.instrumentId, this.isBuyOrder ? "BUY" : "SELL", this.quantityToSell, this.limitAmountToGet,
                        new BigNumber(0), timeStamp * (1000), "PENDING", txID);
                    console.log('order result', timestampOfSubmission, txID, result);
                });
            }
            catch (e)
            {
                console.log("Caught exception will check allowance");

                console.log('>> will check allowance', this.sellToken, this.quantityToSell);
                const result: boolean = await setAllowance(this.sellToken, +(this.quantityToSell), chainInfo.smartContractAddress);
                if (result)
                {
                    console.log("Updated allowance")
                }
                else
                {
                    console.error('Allowance checking didnt work was a different TX error', (e as any));
                }
            }
        }
        else
        {
            console.log('no transaction; no wallets/chains', wallets.length, chainInfo);
        }
    }

    async sendDtwOrder(isSell: boolean)
    {
        const currentState = store.web3Onboard.state.get()
        const wallets = currentState.wallets;
        console.log("Backend info", store.backendInfo);
        const chainInfo = store.getChainInfo();
        console.log("Chain info", chainInfo);
        if (wallets.length > 0 && chainInfo)
        {
            const wallet = wallets[0];
            const walletProvider = store.getWalletProvider(wallet);
            console.log('Sending transaction with wallet ', wallet, " provider", walletProvider);
            try
            {
                const amount: ethers.BigNumber = utils.parseUnits(this.quantityToSell.toFixed(store.tokenDPs[this.sellToken]), store.tokenDPs[this.sellToken]);
                const limit: ethers.BigNumber = utils.parseUnits(this.limitAmountToGet.toFixed(store.tokenDPs[this.buyToken]), store.tokenDPs[this.buyToken]);

                const signer = walletProvider.getUncheckedSigner();

                const chainId = chainInfo.chainId;
                const dtwAddress = chainInfo.dtwContractAddress;

                let sellingTokenAddress = store.tokenAddresses[this.sellToken];
                if (sellingTokenAddress == '0x0000000000000000000000000000000000000000')
                {
                    sellingTokenAddress = chainInfo.wethContractAddress;
                }
                let buyingTokenAddress = store.tokenAddresses[this.buyToken];
                if (buyingTokenAddress == '0x0000000000000000000000000000000000000000')
                {
                    buyingTokenAddress = chainInfo.wethContractAddress;
                }

                const deadline = (+Date.now()) + 3600; // TODO: Put an actual deadline

                const domain = {
                    name: "DTW",
                    version: "1",
                    chainId: chainId,
                    verifyingContract: dtwAddress,
                }
                const types = {
                    Order: [
                        {name: "user", type: "address"},
                        {name: "buyingToken", type: "address"},
                        {name: "limitAmountToBuy", type: "uint256"},
                        {name: "sellingToken", type: "address"},
                        {name: "amountToSell", type: "uint256"},
                        {name: "deadline", type: "uint256"}
                    ]
                }
                const values = {
                    user: wallet.accounts[0].address,
                    buyingToken: buyingTokenAddress,
                    limitAmountToBuy: limit,
                    sellingToken: sellingTokenAddress,
                    amountToSell: amount,
                    deadline: deadline,
                }

                const signature = await signer._signTypedData(domain, types, values);
                const orderRequest: OrderRequest = {
                    instructionId: uuidv4(),
                    orderSource: ORDER_SOURCE_FROM_CHAIN_ID[Number(chainId)],
                    side: isSell ? "BUY" : "SELL",
                    instrument:  {
                        instrumentId: this.instrumentId,
                        baseAsset: isSell ? buyingTokenAddress : sellingTokenAddress,
                        quoteAsset: isSell ? sellingTokenAddress : buyingTokenAddress
                    },
                    amountToSell: this.quantityToSell.toString(),
                    limitAmountToReceive: this.limitAmountToGet.toString(),
                    deadline: deadline.toString(),
                    signature: signature
                }
                console.log("Order orderRequest", orderRequest);

                const response = await sendOrderRequest(orderRequest);
                const responseObject = await response.json();

                console.log("Order response", responseObject);

            }
            catch (e)
            {
                console.log("Caught exception will check allowance");

                console.log('>> will check allowance', this.sellToken, this.quantityToSell);
                const result: boolean = await setAllowance(this.sellToken, +(this.quantityToSell), chainInfo.dtwContractAddress);
                if (result)
                {
                    console.log("Updated allowance")
                }
                else
                {
                    console.error('Allowance checking didnt work was a different TX error', (e as any));
                }
            }
        }
        else
        {
            console.log('no transaction; no wallets/chains', wallets.length, chainInfo);
        }
    }

}


export function sendTransaction(isDtw: boolean)
{

  if (store.leverage > 1)
  {
    let instSymbol: string = "lev_" + store.frontToken + '/' + store.backToken;
    let {id, inverted} = store.instrumentPairs[instSymbol];
    addLeveragePosition(id, new BigNumber(store.sellAmount), new BigNumber(store.leverage))
    .then(() => console.log('Position added successfully'))
    .catch(err => console.error('Error adding position:', err));
  }
  else {
    let instSymbol: string = store.frontToken + '/' + store.backToken;
    let {id, inverted} = store.instrumentPairs[instSymbol];
    let transaction: Transaction = new Transaction(
      store.frontToken,
      store.backToken,
      id,
      inverted,
      new BigNumber(store.sellAmount),
      store.limitAmount ? new BigNumber(store.limitAmount) : new BigNumber(0)
    );
    if (isDtw)
    {
      transaction.sendDtwOrder(inverted);
    }
  else
    {
      transaction.send();
    }
  }
}
