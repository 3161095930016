<script setup lang="ts">

const props = defineProps<{instructionId: string, truncatedInstructionId: string, positionIndex: number }>();

async function writeClipboardText(text) {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <div class="history-item-container">
    <div class="history-item-right">
      <o-tooltip v-bind:label="props.instructionId" position="bottom">
        <div :id="'history-item-' + props.positionIndex + '-place'" class="history-item-place" @click="writeClipboardText(props.instructionId)">Place: <a>{{ props.truncatedInstructionId }}</a></div>
      </o-tooltip>
    </div>
  </div>
</template>

<style scoped>
.history-item-container {
  display: flex;
}

.history-item-right {
  display: inline;
}

.history-item-place {
  display: block;
  font-family: WorkSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
}
</style>