<script setup lang="ts">
import CoinPicker from './CoinPicker.vue';
import { store } from "../../store/Store";
import { nextTick, ref, onMounted, watch } from "vue";
import { validateInputForDecimal, validatePasteForDecimal } from "../../utils/commonUtils";
import BigNumber from "bignumber.js";

const props = defineProps<{ token: string, amount: BigNumber, ableToEditValue: boolean, which: string, extraClass: string }>();
const pickerModalVisible = ref(false);
const sellAmountToAction = ref(new BigNumber(0.01));

const focusSymbol = (target: any) => {
  nextTick(() => {
    target.select();
  });
}

const amountUpdated = (event: any) => {
  if (props.which == 'front') {
    if (event.target.value != '')
    {
      sellAmountToAction.value = event.target.value;
      store.setSellAmount(new BigNumber(event.target.value))
    }
  }
}


onMounted(() => {
  watch(() => store.sellAmount, () => {
    var sellAmountInstance = store.sellAmount.toString().split('.');
    if ((sellAmountInstance.length != 1  || new BigNumber(sellAmountToAction.value).eq(store.sellAmount)) && (sellAmountInstance[1] == "" ||
      sellAmountInstance[1] == undefined ||
      new BigNumber(sellAmountInstance[1]).eq(0))) {
      return false;
    }
    sellAmountToAction.value = store.sellAmount;
  });
});

const changeSymbol = (symbol) => {
  console.log('changeSymbol', symbol, store);
  if (!store) {
    console.log('changeSymbol: store is undefined');
    return;
  }
  if (props.which == 'back') {
    if (symbol == store.frontToken) {
      store.invertPair();
    }
    else {
      store.backToken = symbol;
    }
  }
  else {
    if (symbol == store.backToken) {
      store.invertPair();
    }
    else {
      store.frontToken = symbol;
    }
  }
  store.findInstrument();
  store.rateLastUpdate = 0;
}

const closeModal = () => {
  pickerModalVisible.value = false;
}

</script>

<template>
  <div :class="['container-inline', 'container-picker', extraClass]">
    <div :id="'alice-price-picker-' + which" class="alice-price-picker" @click="pickerModalVisible = true">
      <img v-bind:src="'/crypto-icons/' + props.token.toLowerCase() + '.svg'" class="alice-crypto-icon-small" />
      <span class="alice-crypto-icon-label">
        {{ props.token }} <o-icon pack="fas" icon="angle-down" class="alice-crypto-select"> </o-icon>
      </span>
    </div>
    <div :class="'alice-price-value alice-price-value-' + props.which">

      <input v-if="props.ableToEditValue" v-model="sellAmountToAction"
        :class="'alice-price-value-edit alice-price-value-edit-' + props.which" placeholder="Amount To Sell"
        @focus="focusSymbol($event.target)" @keydown="validateInputForDecimal" @paste="validatePasteForDecimal"
        @keyup="amountUpdated" />

      <span v-else :class="'alice-price-value-edit alice-price-value-edit-' + props.which">{{
    props.amount.toFixed(5, BigNumber.ROUND_FLOOR)
  }}</span>
    </div>
    <o-modal v-model:active="pickerModalVisible" class="alice-price-picker-modal" width="300px" mobileBreakpoint="960px"
      scroll="clip">
      <coin-picker :side="props.which" title="Select a token" v-model="props.token" @token="changeSymbol"
        @close="closeModal" :includeSearch="true" />
    </o-modal>
  </div>
</template>

<style scoped>
.container-picker {
  display: inline-block;
  flex-grow: 0;
  width: 100%;
  margin: 0px;
  padding: 1px 3px 2px 3px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  font-family: WorkSans;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
}

.alice-price-picker {
  display: inline-block;
  width: 170px;
  height: 40px;
  margin: 7px 0px 0 0;
  padding: 10px 6px 10px 4px;
  border-radius: 28px;
  box-shadow: 0 2px 6px 0 rgba(214, 214, 214, 0.5);
  background-color: #fff;
}

.alice-price-value {
  display: inline-block;
  margin: 0px 0px 0px 0px;
  height: 40px;
  width: 55%;
  padding: 0 20.7px 1px 19.6px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: right;
}

.alice-price-value:focus-within {
  background-color: #ffffff;
}

.alice-price-value-edit {
  background-color: #ffffff;
  margin-top: 8px;
  height: 48px;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  font-family: WorkSans;
  line-height: 53px;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  border: 0;
  outline: none;
}

.alice-price-value-edit:focus {
  color: #000000;
}

.alice-price-value-edit:active,
.alice-price-value-edit:focus {
  border: 0;
  box-shadow: 0 0 5px 5px #9ACC82;
}

.alice-crypto-icon-small {
  position: absolute;
  height: 24px;
}

.alice-crypto-icon-label {
  font-family: WorkSans;
  padding-left: 28px;
}

.alice-crypto-select {
  color: var(--select-button-colour);
  transform: translate(0, 2px);
}

.alice-crypto-select:hover {
  color: #000000;
}

.alice-price-picker-modal {
  width: 100%;
  background-color: #ffffff;
}

/* formats for narrow screens */
@media (max-width: 959px) {
  .alice-price-value-edit {
    width: 100%;
  }

  .alice-price-picker {
    width: 116px;
  }
}

@media (max-width: 330px) {
  .alice-price-value-edit {
    width: 100%;
    height: 40px;
  }

  .alice-price-picker {
    width: 116px;
    height: 40px;
  }

  .alice-price-value-edit {
    margin-top: 0px;
    height: 40px;
  }

  .alice-price-value {
    margin: 0px 0 0px;
  }

  .container-picker {
    margin-top: -15px;
  }
}
</style>
