<script setup lang="ts">
import HistoryHeader from './elements/HistoryHeader.vue';
import {onMounted, ref, watch} from "vue";
import DepositButton from "./elements/DepositButton.vue";
import WithdrawButton from "./elements/WithdrawButton.vue";
import {store} from "../store/Store";
import Balance from "./elements/Balance.vue";
import {getDepositBalance, getTokenBalance} from "../lib/Wallet";
import PrefundedBalanceNarrowLayout from "./PrefundedBalanceNarrowLayout.vue";


const props = defineProps<{ title: string, extraClass: string, useNarrowLayout: boolean, walletConnected: boolean }>();
const prefundedBalanceList = ref([]);
const instrumentList = ref([]);

onMounted(() => {
  watch(() => store.walletIsConnected, () => {
    poll();
  });
})

const columns = ref([
    {
        field: "instrumentId",
        label: "Tokens",
        icon :"search",
        placeholder:"Search...",
        searchable: true,
        width:250,
    }
]);

async function poll() {
  
  try {
    instrumentList.value = Object.keys(store.instrumentTokens)?.sort();
    if (store.walletIsConnected) {
      const currentState = store.web3Onboard.state.get()
      const wallet = currentState.wallets[0];
      await Promise.all(instrumentList.value?.map(async (instrumentId) => {
        const wp = store.getDataProvider(wallet);
        const tokenBalance = await getTokenBalance(instrumentId, wp, wallet.accounts[0].address);
        const depositedBalance = await getDepositBalance(instrumentId, wp);
        return {
          instrumentId,
          tokenBalance,
          depositedBalance
        };
      })).then(data => (prefundedBalanceList.value = data));
    } else {
      setPrefundedDefault();
    }
  } catch (error) {
    console.log(error);
    setPrefundedDefault();
  } finally {
    setTimeout(poll, store.pollFreq);
  }
}

const setPrefundedDefault = () => {
  prefundedBalanceList.value = (instrumentList.value?.map((instrumentId) => {
          return {
              instrumentId,
              tokenBalance:"",
              depositedBalance:"",
          };
      }));
}


</script>

<template>
<section>
<div :class="['container-balance-panel',extraClass]">
    <HistoryHeader :title="title" class="alice-balance-header" v-if="!store.useNarrowLayout"></HistoryHeader>
    <div class="alice-ticket-content" v-if="!store.useNarrowLayout">
      <o-table :data="prefundedBalanceList" class="alice-balance-table" :debounce-search="1000">
        <o-table-column
            v-for="(column, idx) in columns"
            :key="idx"
            v-slot="{ row }"
            v-bind="column">
            {{ row[column.field] }}
        </o-table-column>
        <o-table-column field="balance" label="Wallet Balance" class="alice-balance-column"  v-slot="props">
          <Balance title="" :token="props.row.instrumentId" :amount="props.row.tokenBalance" which="front" extra-class="alice-balance-column"></Balance>
        </o-table-column>
        <o-table-column field="balance" label="Smart Contract Balance" class="alice-balance-column"  v-slot="props">
          <Balance title="" :token="props.row.instrumentId" :amount="props.row.depositedBalance" which="front" extra-class="alice-balance-column"></Balance>
        </o-table-column>
        <o-table-column field="deposit" class="alice-balance-column" v-slot="props">
          <DepositButton title="Deposit" extra-class="" :token="props.row.instrumentId" :walletBalance="props.row.tokenBalance" :smartContractBalance="props.row.depositedBalance"></DepositButton>
        </o-table-column>
        <o-table-column field="withdraw" class="alice-balance-column" v-slot="props">
          <WithdrawButton title="Withdraw" extra-class="" :token="props.row.instrumentId" :walletBalance="props.row.tokenBalance" :smartContractBalance="props.row.depositedBalance"></WithdrawButton>
        </o-table-column>
      </o-table>
    </div>
    <div v-else>
      <PrefundedBalanceNarrowLayout title="" extraClass="" 
      :useNarrowLayout="store.useNarrowLayout" :isDtw="true" 
      :walletConnected = "props.walletConnected"
      :prefundedBalanceList="prefundedBalanceList"></PrefundedBalanceNarrowLayout>
    </div>
  </div>
</section>
</template>

<style scoped>
.container-balance-panel {
  display: block;
}

.alice-balance-header {
  position: absolute;
  margin-top: 10px;
}

.alice-balance-value-edit {
  background-color: #ffffff;
  margin-top: 8px;
  height: 48px;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  font-family: WorkSans;
  line-height: 53px;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  border: 0;
  outline: none;
}

.alice-balance-column {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  font-family: WorkSans;
  margin-left: -0.5px;
  margin-top: 13.5px;
  letter-spacing: normal;
  text-align: left;
  color: var(--balance-text-colour);
  outline: none;
}
</style>