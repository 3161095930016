import {addApiUrlsToStore} from "./lib/Hostnames";
import {createApp} from 'vue'
import Oruga from '@oruga-ui/oruga-next'

//import {CIQ} from 'chartiq/js/advanced'
//import getLicenseKey from "chartiq/key.js";
//getLicenseKey(CIQ);

import * as Sentry from '@sentry/vue'

import './fonts.css'
import '@oruga-ui/oruga-next/dist/oruga-full.css'
import './style.css'

import App from './App.vue'
//App.CIQ = CIQ;

import {resolveEnv, store} from './store/Store';
import {initWalletConnection} from './lib/Wallet';
import {loaders} from './store/Loaders';
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowRightArrowLeft,
  faArrowsRotate,
  faArrowsUpDown,
  faArrowUp,
  faBook,
  faChartSimple,
  faCircleInfo,
  faCircleXmark,
  faClock,
  faEllipsisH,
  faEllipsisV,
  faMagnifyingGlass,
  faTimes,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import {createPinia} from "pinia";
import {initialiseSiweStore} from "./lib/SiweClient";

var global = window;
window['global'] = global;


console.log('Starting @',  (new Date()).getTime());

const myUrl = new URL(window.location.toLocaleString());
const themeParam = myUrl.searchParams.get('theme');
if (themeParam=='dark') {
  document.body.classList.add(themeParam);
}

const NARROW_WIDTH = 960;

function adjustSizes(newWidth) {
  store.windowWidth = newWidth;
  store.useNarrowLayout = newWidth < NARROW_WIDTH;
}

adjustSizes(window.innerWidth);

new ResizeObserver(() => {
  adjustSizes(window.innerWidth);
}).observe(document.body)

resolveEnv();

/* preload data */
addApiUrlsToStore().then(() => {
  loaders.loadInstruments().then(()=>{
    initialiseSiweStore();
    initWalletConnection();
  });
});


/* add icons to the library */
library.add(
  faArrowUp,
  faArrowDown,
  faArrowsRotate,
  faArrowsUpDown,
  faArrowRightArrowLeft,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faMagnifyingGlass,
  faTimes,
  faXmark,
  faClock,
  faChartSimple,
  faCircleXmark,
  faBook,
  faCircleInfo,
  faEllipsisV,
  faEllipsisH
)

const pinia = createPinia();

const app = createApp(App)
.use(Oruga, {
  iconComponent: 'font-awesome-icon',
  iconPack: 'fas'
})
    .use(pinia)
.component('font-awesome-icon', FontAwesomeIcon)

if (import.meta.env.PROD)
{
  Sentry.init({
    app,
    dsn: 'https://24100a99bfee44df82f4d5df09341930@o4504994517745664.ingest.us.sentry.io/4504994564538368',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/api\.demo\.project-alice\.io/, /^https:\/\/api\.production\.project-alice\.io/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    allowUrls: [/https:\/\/(demo\.)?project-alice\.io/]
  })
}

app.mount('#app');
