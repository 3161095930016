<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import CloseButton from './elements/CloseButton.vue'
import {getLeveragePositions} from "../lib/LeveragePositions";

import { store } from "../store/Store";

const props = defineProps<{ title: string, extraClass: string, useNarrowLayout: boolean, walletConnected: boolean, isDtw: boolean }>();

onMounted(() => {
  showOrderTypeList();
});

const showOrderTypeList = () => {
  // selectedOrderType.value = orderType[0]
  // orderTypeList.value = Object.assign(orderType);
}
</script>

<template>
  <div :class="['container-history-panel', extraClass]" class="order-position-container">
    <div class="alice-ticket-content">
      <div v-for="(row, i) in store.leveragePositions" :key="i" class="card" :id="'order-history-entry-' + row.positionIndex">
        <div class="card-header text-align-left">
          <div class="card-header-content">
            <img v-bind:src="'/crypto-icons/' + row.sellSymbol.toLowerCase() + '.svg'" class="card-header-img-left" />
            <img v-bind:src="'/crypto-icons/' + row.buySymbol.toLowerCase() + '.svg'" class="card-header-img-right" />
          </div>
          <div class="card-header-content-text">{{ row.buySymbol }}/{{ row.sellSymbol }}</div>
        </div>
        <div class="card-body">
          <div class="text-align-left">
            <div class="row">
              <label>
                Stake:
                <span class="font-weight-bold"> {{ row.stake }} </span>
              </label>
            </div>
            <div class="row">
              <label>
                Open Price:
                <span class="font-weight-bold"> {{ row.openPrice }} </span>
              </label>
            </div>
            <div class="row">
              <label>Position Size:
                <span class="font-weight-bold">{{ row.unitsOfExposure }} {{ row.buySymbol }}</span>
              </label>
            </div>
            <div class="row">
              <label>P/L:
                <span class="font-weight-bold"> <span :class="row.pl >= 0 ? 'positive-pl' : 'negative-pl'"><span v-if="row.pl > 0">+</span>{{ row.pl }} {{ row.sellSymbol }}</span></span>
              </label>
            </div>
          </div>
          <div class="card-divider"></div>
          <div class="card-footer">
            <div class="card-body-button">
              <CloseButton title="Close" extra-class='' :token="row.token" :chain-instrument-id="row.chainInstrumentId">
              </CloseButton>
            </div>
          </div>
        </div>
      </div>
      <div v-if="walletConnected">
        <div v-if="store.leveragePositions.length == 0">
          <div class="history-status">
            No positions currently
          </div>
        </div>
      </div>
      <div v-else>
        <div class="history-status">
          {{ 'Please connect wallet to see positions.' }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media (max-width: 959px) {

  .alice-ticket-content {
    margin-top: 10px;
    max-height: 68dvh;
    overflow: auto;
    position: relative;
  }

  .card {
    width: 100%;
    border-radius: 4px;
    border: solid 2px #eaeaea;
    background-color: var(--buttonbar-background-colour);
  }

  .card-header {
    height: 2.2rem;
    display: flex;
    border-bottom: solid 1px #eaeaea;
  }

  .card-header-img-left {
    padding: 4px 2px 9px 6px;
    width: 30px;
  }

  .card-header-img-right {
    padding: 4px 2px 9px 6px;
    margin-left: -10px;
    width: 30px;
  }

  .card-header-content-text {
    padding: 4px 3px 0px 6px;
    font-size: 14px;
  }

  .card-body {
    width: 100%;
    flex-grow: 0;
    margin: 0px 0px 0px 0px;
    padding: 5px 6.4px 41px 8.6px;
    background-color: var(--card-background-colour);
  }

  .card-divider {
    padding: 1px;
    flex-grow: 0;
    background-color: #eaeaea;
  }
  .row {
    margin: 5px 2px 2px 5px;
  }

  .card-footer {
    widows: 100%;
    padding: 10px 0px 10px 0px;
  }

  .card-body-button {
    max-height: fit-content;
    width: fit-content;
    float: left;
    width: 50%;
    padding: 0px 0px 0px 10px;
  }
}

.positive-pl
{
  color : green;
}

.negative-pl
{
  color : red;
}
</style>
