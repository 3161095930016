<script setup lang="ts">
import {store} from '../../store/Store';
import {onMounted,watch,ref} from "vue";
import {withdrawTokens} from "../../lib/Wallet";
import {validateInputForDecimal ,validatePasteForDecimal } from "../../utils/commonUtils";
import BigNumber from "bignumber.js";

const props = defineProps<{token: string, walletBalance: BigNumber, smartContractBalance: BigNumber }>();
const walletBalanceBalanceAction = ref(new BigNumber(0));
const smartContractBalanceAction = ref(new BigNumber(0));

const emit = defineEmits(['close'])
const amountToAction = ref(0);

const closeModal = () => {
  emit('close');
}

onMounted(() => {
  if( props.smartContractBalance && props.smartContractBalance.gte(0)){
    smartContractBalanceAction.value = props.smartContractBalance;
  }

  if(props.walletBalance && props.walletBalance.gte(0)){
    walletBalanceBalanceAction.value = props.walletBalance;
  }

  watch(() => props.smartContractBalance, () => {
    smartContractBalanceAction.value = props.smartContractBalance
  });

  watch(() => props.walletBalance, () => {
    walletBalanceBalanceAction.value = props.walletBalance
  });
});


const withdraw = async () => {
  console.log("Withdraw: ", props.token, " ", amountToAction.value);
  const currentState = store.web3Onboard.state.get()
  const wallet = currentState.wallets[0];
  const walletProvider = store.getWalletProvider(wallet);
  withdrawTokens(props.token, walletProvider, new BigNumber(amountToAction.value), wallet.accounts[0].address)
  closeModal();
}



</script>

<template>
  <div class="alice-popup-container" id="withdrawModalContainer">
    <div class="alice-popup-title">
      <span class="align-left">
        {{ "Withdraw" }}
      </span>
      <span class="align-right">
        <o-icon pack="fas" icon="xmark" class="alice-popup-close-icon" @click="closeModal"/>
      </span>
    </div>
    <div class="alice-popup-rule"></div>
    <div class="popup_content">
      <section>
        <div class="withdraw_section_row">
          <div>Wallet Balance After Withdrawal</div>
          <div v-if="(smartContractBalanceAction.lt(amountToAction))" class="confirm-swap-token">
            <span class="confirm-swap-token" id="walletTokenAmount">{{ walletBalanceBalanceAction.valueOf() }}&nbsp;</span>
            <span class="confirm-swap-token" id="walletTokenName">{{ props.token }}</span>
          </div>
          <div v-else class="confirm-swap-token">
            <span class="confirm-swap-token" id="walletTokenAmount" v-if="amountToAction>0">{{ walletBalanceBalanceAction.plus(amountToAction) }}&nbsp;</span>
            <span class="confirm-swap-token" id="walletTokenAmount" v-else>{{ walletBalanceBalanceAction.valueOf() }}&nbsp;</span>

            <span class="confirm-swap-token" id="walletTokenName">{{ props.token }}</span>
          </div>
        </div>
        <div class="withdraw_section_row">
          <div>Smart Contract After Withdrawal</div>
          <div v-if="(smartContractBalanceAction.lt(amountToAction)) && (smartContractBalanceAction.lt(0))" class="confirm-swap-token">
            <span id="contractTokenAmount">{{ 0 }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else-if="smartContractBalanceAction.lt(0)" class="confirm-swap-token">
            <span id="contractTokenAmount">{{ 0 }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else-if="smartContractBalanceAction.lt(amountToAction)" class="confirm-swap-token">
            <span id="contractTokenAmount">{{ 0 }}&nbsp;</span>
            <span id="contractTokenName">{{ props.token }}</span>
          </div>
          <div v-else class="confirm-swap-token">
            <span id="contractTokenAmount" v-if="new BigNumber(amountToAction).gte(0)">{{ smartContractBalanceAction.minus(new BigNumber(amountToAction)) }}&nbsp;</span>
            <span id="contractTokenAmount" v-else>{{ smartContractBalanceAction.valueOf()}}&nbsp;</span>

            <span id="contractTokenName">{{ props.token }}</span>
          </div>
        </div>
        <div class="withdraw_section_row input_amount_withdraw">
          <o-input v-model="amountToAction" type="text" placeholder="Withdraw Amount" id="amountEntryField"  @keydown="validateInputForDecimal" @paste="validatePasteForDecimal"></o-input> 
          <!-- <o-input v-model="amountToAction" @keydown="checkDecimal" placeholder="Withdraw Amount" id="amountEntryField"></o-input> -->
        </div>
        <div class="button_container">
          <div v-if="(smartContractBalanceAction.gte(amountToAction))">
            <button id="confirmAction" class="button_popup" @click="withdraw">{{ "Withdraw" }}</button>
          </div>
          <div v-else>
            <button id="confirmAction" class="button_popup">{{ "Invalid Withdraw Amount" }}</button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>

.withdraw_section_row {
  float: left;
  margin-bottom: 10px;
}

.confirm-swap-token {
  font-size: 18px;
}

.input_amount_withdraw {
  width: 240px
}
</style>