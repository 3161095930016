import {defineStore} from "pinia";
import {ref} from "vue";
import type {Order, OrderStatus} from "../types/index.d.ts";
import {store} from "./Store";
import {Contract} from "ethers";

export const useOrderHistoryStore = defineStore('order-history', () => {
    const orders = ref<Order[]>([]);

    function upsertOrder(order: Order)
    {
        for (let index in orders.value)
        {
            const o = orders.value[index];
            if (o.placeTransactionId == order.placeTransactionId)
            {
                if(isValidStateTransition(o.status, order.status))
                {
                    orders.value[index] = order;
                }
                return;
            }
        }
        orders.value.push(order);
    }

    function isValidStateTransition(oldStatus: OrderStatus, newState: OrderStatus): boolean
    {
        switch (oldStatus)
        {
            case "PENDING":
                return newState == "PENDING" || newState == "PENDING_SETTLE" || newState == "PENDING_CANCEL" || newState == "REFUNDED" || newState == "FAILED" || newState == "CANCELLED";
            case "PENDING_SETTLE":
                return newState == "SETTLED" || newState == "FAILED" || newState == "REFUNDED";
            case "PENDING_CANCEL":
                return newState == "CANCELLED" || newState == "FAILED" || newState == "REFUNDED";
            case "SETTLED":
                return false;
            case "CANCELLED":
                return false;
            case "FAILED":
                return false;
            case "REFUNDED":
                return false;
        }
    }

    async function checkForRefunds()
    {
        const currentState = store.web3Onboard.state.get()
        const wallets = currentState.wallets;
        if (wallets.length > 0)
        {
            const chainInfo = store.getChainInfo();
            const sc: Contract = new Contract(chainInfo.smartContractAddress, chainInfo.abi, store.getDataProvider(wallets[0]));
            const isSmartContractActive: boolean = await sc.active();
            for (const order of orders.value)
            {
                if (isOrderRefundable(order, isSmartContractActive))
                {
                    try
                    {
                        await sc.getOrderHash(order.orderId);
                        order.refundable = true;
                    }
                    catch (e)
                    {
                        // If the call reverts this means the order no longer exists on chain and so we assume it has been refunded
                        order.status = "REFUNDED"
                        order.refundable = false;
                    }
                }
                else
                {
                    order.refundable = false;
                }
            }
        }

    }

    function isOrderRefundable(order, isSmartContractActive)
    {
        const refundDurationSeconds = (store.backendInfo as any).refundDurationSeconds;
        return (order.orderId && (order.status === "PENDING" || order.status === "PENDING_CANCEL" || order.status === "PENDING_SETTLE") &&
                (!isSmartContractActive || order.timestamp < Date.now() - refundDurationSeconds * 1000));
    }

    setInterval(checkForRefunds, store.pollFreq);

    return {orders, upsertOrder};
})