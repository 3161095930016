import {DateTime} from 'luxon';


const UNITS = {
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
}

export function calculatePeriodicity(interval)
{
  let unit = interval.slice(-1);
  let num = +interval.slice(0, -1);
  let periodicity = {
    period: 1,
    interval: num,
    unit: unit,
    timeUnit: UNITS[unit]
  };

  return periodicity;
}

export function calculateRangeAndPeriodicity(interval)
{
  const dateNow = DateTime.now();
  let startDate = dateNow;
  const periodicity = calculatePeriodicity(interval);
  switch (interval)
  {
    case '1m':  // Hour
      startDate = startDate.minus({hours: 1});
      break;
    case '15m': // Day
      startDate = startDate.minus({days: 1});
      break;
    case '3h':  // Week
      startDate = startDate.minus({weeks: 1});
      break;
    case '1d':  // Month
      startDate = startDate.minus({months: 1});
      break;
    case '1w':  // Year
      startDate = startDate.minus({years: 1});
      break;
  }

  let range = {
    dtLeft: startDate.toJSDate(),
    dtRight: dateNow.toJSDate()
  }

  return {
    range,
    periodicity
  }
}