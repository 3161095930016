<script setup lang="ts">

import {store} from '../store/Store';
import image from "../assets/alice-icon-transparent.png"

const imageData = image;

</script>

<template>
  <div class="logo-container">
    <o-tooltip position="right" :label="store.appname+' '+store.version">
      <img class="logo" :src="imageData" role="img" alt="Alice Logo"/>
    </o-tooltip>
  </div>
</template>