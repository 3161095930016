<script setup lang="ts">
import {store} from '../../store/Store';
import type {Ref} from "vue";
import {onMounted, ref, watch} from "vue";
import {sendTransaction, Transaction} from "../../lib/Transaction";
import {isDef} from "@vueuse/core";

const props = defineProps<{ title: string, isDtw: boolean }>();

const showAuthorizeMessage = ref(false);
const swapButtonText = ref("");
const txID = ref("");
const state = ref(false);
const emit = defineEmits(['close'])

const updateSwapButton = () => {
  if (store.isWhitelisted) {
    if (store.frontAllowance.gte(store.sellAmount) || store.tokenAddresses[store.frontToken] == '0x0000000000000000000000000000000000000000') {
      swapButtonText.value = 'Confirm Swap';
      showAuthorizeMessage.value = false;
    } else {
      swapButtonText.value = 'Authorize';
      showAuthorizeMessage.value = true;
    }
  }
}

const closeModal = () => {
  emit('close');
}

const confirmSwap = () => {
  sendTransaction(props.isDtw);
  closeModal();
}

onMounted(() => {
  console.log('swap popup mounted');
  updateSwapButton();
  watch(() => store.frontAllowance, () => {
    updateSwapButton();
  });
})
</script>

<template>
  <div class="alice-popup-container">

    <div class="alice-popup-title">
      <span v-if="store.isWhitelisted" class="align-left">
        {{ props.title }}
      </span>
      <span v-else class="align-left">
        {{ "Unverified user" }}
      </span>
      <span class="align-right">
        <o-icon pack="fas" icon="xmark" class="alice-popup-close-icon" @click="closeModal"/>
      </span>
    </div>

    <div class="alice-popup-rule"></div>

    <div v-if="state">
      <div>Transaction status</div>
      <div>{{ state }}</div>
      <div v-if="txID">Transaction ID</div>
      <div v-if="txID">{{ txID }}</div>
    </div>
    <div v-else>
      <div>
        <div v-if="store.isWhitelisted == false" class="alice-swap-popup-content">
          <div class="parallel-text">
            You are not whitelisted.
          </div>
          <div class="parallel-text">
            Please get in touch and request a whitelist approval for your wallet.
          </div>
        </div>
        <div v-else-if="showAuthorizeMessage" class="alice-swap-popup-content">
          <div class="parallel-text">
            Before proceeding, please use your wallet to authorise spending tokens
          </div>
        </div>
        <div v-else class="alice-swap-popup-content">
          <div class="confirm-swap-label">You sell</div>
          <div class="confirm-swap-amount">{{ store.sellAmount }} <span class="confirm-swap-token">{{ store.frontToken }}</span></div>
          <div class="confirm-swap-label">You get</div>
          <div class="confirm-swap-amount">{{ store.buyAmount }} <span class="confirm-swap-token">{{ store.backToken }}</span></div>
        </div>
        <div v-if="store.isWhitelisted == false">
          <button id="closeModalButton" class="button_confirm_swap" @click="closeModal">{{ "Close" }}</button>
        </div>
        <div v-else>
          <button id="confirmSwapBtn" class="button_confirm_swap" @click="confirmSwap">{{ swapButtonText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.parallel-text {
  margin: 10px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
}

.confirm-swap-label {
  margin-left: 30px;
  margin-top: 20px;
  font-family: WorkSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.25px;
  text-align: left;
  color: #94c27e;
}

.confirm-swap-amount {
  margin-left: 30px;
  margin-top: 10px;
  font-family: WorkSans;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.25px;
  text-align: left;
  color: #212121;
}

.confirm-swap-value {
  margin-left: 30px;
  margin-top: 8px;
  font-family: WorkSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.25px;
  text-align: left;
  color: #a9abac;
}

.confirm-swap-token {
  font-size: 18px;
}

.button_confirm_swap {
  width: 200px;
  margin-top: 20px;
}
</style>
