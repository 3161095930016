import {store} from '../store/Store';
import {getSiweJwt} from "./SiweClient";

export type WithdrawalRequest = {
    instructionId: string;
    orderSource: string;
    tokenAddress: string;
    amount: string;
    deadline: string;
    signature: string;
}
export type OrderRequest = {
    instructionId: string;
    orderSource: string;
    side: string;
    instrument: { instrumentId: number, baseAsset: string, quoteAsset: string };
    amountToSell: string;
    limitAmountToReceive: string;
    deadline: string;
    signature: string;
}
const urls = {
    withdrawal: () => store.restApiUrl + "/withdraw", // POST
    withdrawalStatus: () => store.restApiUrl + "/withdraw", // GET, requires /:instructionId

    balance: () => store.restApiUrl + "/balance", // GET requires /:source

    order: () => store.restApiUrl + "/order", // POST
    orderStatus: () => store.restApiUrl + "/order" // GET, requires /:source/:instructionId
}

function getHeaders() {
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getSiweJwt()
    }
}

async function sendGetRequest(url: string) {
    const request: Request = new Request(url, {
        method: "GET",
        headers: getHeaders()
    });
    return fetch(request);
}

async function sendPostRequest(url: string, payload: object) {
    const request: Request = new Request(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(payload)
    });
    return fetch(request);
}

export async function sendWithdrawalRequest(payload: WithdrawalRequest) {
    return await sendPostRequest(urls.withdrawal(), payload);
}
export async function getWithdrawalStatus(instructionId: string) {
    const url: string = urls.withdrawalStatus() + "/" + instructionId;
    return await sendGetRequest(url)
}

export async function getRestBalance(orderSource: string) {
    const url: string = urls.balance() + "/" + orderSource;
    return await sendGetRequest(url);
}

export async function sendOrderRequest(payload: OrderRequest) {
    return await sendPostRequest(urls.order(), payload);
}
export async function getOrderStatus(orderSource: string, instructionId: string) {
    const url: string = urls.orderStatus() + "/" + orderSource + "/" + instructionId;
    return await sendGetRequest(url)
}