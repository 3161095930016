import {store} from "../store/Store";
import {DateTime} from 'luxon';
import type {ButtonType, UiOrder, Order} from "../types";
import {OrderStatus} from "../types"
import {BigNumber} from "bignumber.js";
import {ethers} from "ethers";

const STATUS_TO_BUTTON_TYPE: Record<OrderStatus, ButtonType> = {
    'SETTLED': 'primary',
    'PENDING': 'warning',
    'PENDING_CANCEL': 'warning',
    'PENDING_SETTLE': 'warning',
    'CANCELLED': 'danger',
    'FAILED' : 'danger',
    'REFUNDED' : 'info'
};

const STATUS_MAP = {
    'FAILED': 'REFUND',
    'PENDING_SETTLE': 'PENDING SETTLE',
    'PENDING_CANCEL': 'PENDING CANCEL'
};

export function sortByTimestampThenOrderId(a: any, b: any)
{

    const timestampDiff: ethers.BigNumber = ethers.BigNumber.from(a.timestamp).sub(ethers.BigNumber.from(b.timestamp));
    if (timestampDiff.eq(0))
    {
        return a.orderId.sub(b.orderId).toNumber();
    }
    else
    {
        return timestampDiff.toNumber();
    }
}

export function filterOrderSourceByDtw(isDtw: boolean) {
    return function(order: any) {
        if (isDtw === undefined)
        {
            return true;
        }
        const orderSource = order.chainOrderSource;

        return isDtw == orderSource.orderSource.endsWith("_DTW");
    }
}

export function filterByOrderSource()
{
    return function (order: any)
    {
        const chainOrderSource = order.chainOrderSource;

        let dtwOrderSource = store.chainToUse.substring(0, 3) + "_DTW";
        return (chainOrderSource.orderSource == store.chainToUse || chainOrderSource.orderSource == dtwOrderSource)
    }
}

export function formatOrderForUi(order: Order, index: number): UiOrder
{
    const instrumentObj = store.instrumentDict[order.instrumentId];

    return {
        positionIndex: index + 1,
        orderId: order.orderId,
        date: DateTime.fromMillis(order.timestamp).toLocaleString(DateTime.DATETIME_MED),
        buttonType: STATUS_TO_BUTTON_TYPE[order.status],
        statusMessage: mapStatus(order.status),
        limit: parseLimit(order.limitAmount, instrumentObj.baseSymbol),
        sold: parseAndScaleQuantity(order.quantitySold, instrumentObj.baseSymbol),
        received: order.status == 'FAILED' || order.status == 'CANCELLED' || order.status == "REFUNDED" ? '-' : order.status == 'PENDING' ? 'PENDING' : parseAndScaleQuantity(order.quantityObtained,
            instrumentObj.quoteSymbol),
        soldSymbol: order.side == 'SELL' ? instrumentObj.baseSymbol : instrumentObj.quoteSymbol,
        boughtSymbol: order.side == 'SELL' ? instrumentObj.quoteSymbol : instrumentObj.baseSymbol,
        full_tx_id: order.placeTransactionId,
        place_tx: truncateTxID(order.placeTransactionId, 10),
        place_tx_href: store.ethscanPrefix + order.placeTransactionId,
        settle_tx: order.settleTransactionId ? truncateTxID(order.settleTransactionId, 10) : undefined,
        settle_tx_href: order.settleTransactionId ? store.ethscanPrefix + order.settleTransactionId : undefined,
        tx_href: order.refundTransactionId ? store.ethscanPrefix + order.refundTransactionId : order.settleTransactionId ? store.ethscanPrefix + order.settleTransactionId : order.placeTransactionId,
        refundable: order.refundable,
        feePaid: parseLimit(order.feePaid, instrumentObj.baseSymbol),
        quantityObtained: order.status == 'FAILED' || order.status == 'CANCELLED' || order.status == "REFUNDED" ? '' : order.status == 'PENDING' ? 'PENDING' :order.quantityObtained.toString(),
        status: order.status
    }
}
function parseAndScaleQuantity(quantity: BigNumber, symbol: string)
{
    return quantity.toFixed(getDpForToken(symbol), BigNumber.ROUND_FLOOR);
}

function getDpForToken(token)
{
    const defaultDp: number = 5;
    if (token in store.tokenDPs)
    {
        return BigNumber.min(store.tokenDPs[token], defaultDp).toNumber();
    }
    else
    {
        console.log("Did not find token (" + token + ") in store, will default to " + defaultDp)
        return defaultDp;
    }
}

function mapStatus(status: string)
{
    if (status in STATUS_MAP)
    {
        return STATUS_MAP[status];
    }
    else
    {
        return status;
    }
}

function parseLimit(limit: BigNumber, symbol: any)
{
    try{
        if (limit.eq(new BigNumber(0)))
        {
            return '-';
        }
        else
        {
            return parseAndScaleQuantity(limit, symbol);
        }
    }
    catch (e)
    {
        return '-';
    }
}

function truncateTxID(txID: string, len: number)
{
    const halfwayPoint: number = Math.floor(len / 2);
    return txID.slice(0, halfwayPoint) + '...' + txID.slice(-halfwayPoint);
}