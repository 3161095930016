<script setup lang="ts">
import Swaps from '../components/Swaps.vue';
import Balances from "../components/Balances.vue";
import { ref } from "vue";
import { store } from '../store/Store';

const activeTabPreFunded = ref(0);
const props = defineProps<{ title: string, extraClass: string, isDtw: boolean }>();

</script>

<template>
    <div class="alice-prefunded-sub-tab">
        <o-tabs v-model="activeTabPreFunded" type="boxed"  position="left">
            <o-tab-item :value="0" label="Trade">
                <Swaps id="swaps-block" class="alice-ticket swaps-block" extra-class="" title="" v-bind:is-dtw="props.isDtw" />
            </o-tab-item>
            <o-tab-item :value="1" label="Balances" class="alice-prefunded-sub-tab-balances">
                <Balances id="balance-block" class="alice-balance-container balance-block" title="Balances"
                    :use-narrow-layout="store.useNarrowLayout" :wallet-connected="store.walletIsConnected"
                    extra-class="" />
            </o-tab-item>
        </o-tabs>
    </div>
</template>

<style>

@media (max-width: 959px) {
    .alice-prefunded-sub-tab .o-tabs__nav {
        position: relative;
        display: flex;
        overflow: auto;
        left: 22%;
        top: 2px;
        box-shadow: 0 0px 0px 0;
        background-color: var(--body-background-colour);
    }

    .alice-prefunded-sub-tab .o-tabs__nav-item {
        background-color: var(--nav-background-colour);
    }

    .alice-prefunded-sub-tab .o-tabs__content {
        width: 100%;
        padding-top: 0em;
        margin-top: 0px;
    }

    .alice-prefunded-sub-tab .alice-ticket {
        margin-top: 0px;
        padding: 0px 10px 10px 10px;
        border-radius: 8px;
        box-shadow: 0 4px 18px 0 rgba(181, 181, 181, 0.5);
        border: solid 1px #f2f2f3;
    }

    .alice-prefunded-sub-tab-balances {
       margin:2px;
    }
}
</style>