import {store} from "../store/Store";
import {subscribeOnboard} from "./Wallet";
import {reactive} from "vue";
import {SiweMessage} from "siwe";
import {Subscription} from "rxjs";
import {WalletState} from "@web3-onboard/core/dist/types";
import * as Sentry from "@sentry/vue";

function uiControllerApiPrefix()
{
    return store.apiUrl;
}

const urls = reactive({
    uiControllerApiNonceUrl: () => uiControllerApiPrefix() + '/siwe/nonce',
    uiControllerApiVerifyUrl: () => uiControllerApiPrefix() + '/siwe/verify'
});

const STORAGE_KEY_SIWE_TOKEN_UI = 'SIWE_TOKEN_UI';
const STORAGE_KEY_JWT_UI = 'SIWE_JWT_UI';


type SiweStore = {
    currentUser: string;
    token: string;
    JWT: string;
    expires: number;
    onboardEvents: Subscription;
};
const siweStore: SiweStore = {
    currentUser: '',
    token: sessionStorage.getItem(STORAGE_KEY_SIWE_TOKEN_UI) || '',
    JWT: sessionStorage.getItem(STORAGE_KEY_JWT_UI) || '',
    expires: parseJwt(sessionStorage.getItem(STORAGE_KEY_JWT_UI) || '').exp * 1000,
    onboardEvents: null
};

export function getSiweJwt() {
    return siweStore.JWT;
}

export function jwtsAreValid() {
    return siweStore.JWT
        && siweStore.JWT.length > 0
        && siweStore.expires > (+new Date());
}
function jwtsAreValidForStore(siweStore: SiweStore) {
}

export function siweTokenIsValid() {
    return siweStore.token && siweStore.token.length > 0;
}

export function hasOnboardEvents() {
    return siweStore.onboardEvents
}

export function setOnboardEvents(onboardEvents: Subscription) {
    siweStore.onboardEvents = onboardEvents
}
function setOnboardEventsForStore(siweStore: SiweStore, onboardEvents: Subscription) {
}

export function siweUserEquals(user: string) {
    return siweStore.currentUser.toUpperCase() == user.toUpperCase();
}
function siweUserEqualsForStore(siweStore: SiweStore, user: string) {
}

export function initialiseSiweStore() {
    if (siweStore.expires < (+new Date())) {
        signOutAndClearJWT('SIWE token expired; initSIWEstore');
    } else {
        subscribeOnboard();
    }
}

export async function signInWithEthereumAll(wallet: any) {
    await signInWithEthereum(wallet, siweStore, urls.uiControllerApiNonceUrl(), urls.uiControllerApiVerifyUrl());
}

async function signInWithEthereum(wallet: WalletState, siweStore: SiweStore, nonceUrl: string, verifyUrl: string) {
    console.log(wallet);
    return new Promise(async (resolve, reject) => {
        store.pausePolling = true;
        try {
            const nonce: string = await fetchSiweNonce(nonceUrl);
            const walletProvider = store.getWalletProvider(wallet);
            const signer = walletProvider.getUncheckedSigner();
            const address = await signer.getAddress();
            const message = createSiweMessage(
                address,
                nonce,
                'Sign in with Ethereum'
            );

            siweStore.token = await signer.signMessage(message);
            siweStore.JWT = await verifySiwe(siweStore.token, verifyUrl, message);
            siweStore.currentUser = address;
            if (siweStore.JWT && siweStore.JWT.length > 0) {
                siweStore.expires = parseJwt(siweStore.JWT).exp * 1000;
                sessionStorage.setItem(STORAGE_KEY_SIWE_TOKEN_UI, siweStore.token);
                sessionStorage.setItem(STORAGE_KEY_JWT_UI, siweStore.JWT);
                Sentry.setUser({username: address})
                resolve(siweStore.JWT);
                store.pausePolling = false;
            } else {
                console.log('Error: no JWT');
                //setTimeout(signOutAndClearJWT, 100);
                reject(null);
                store.pausePolling = false;
            }
        } catch (e) {
            console.log('siweClient', e);
            walletDisconnected('Wallet is not connected; try again');
            reject(e);
            const [primaryWallet] = store.web3Onboard.state?.get().wallets;
            await store.web3Onboard.disconnectWallet({ label: primaryWallet.label })
            store.pausePolling = false;
        }
    });
}

async function fetchSiweNonce(url: string) {
    let nonce = '0';
    try {
        const res = await fetch(url);
        let data = (await res.json());
        nonce = data.nonce;
        console.log('fetchSiweNonce', nonce);
    } catch (error) {
        console.log('fetchSiweNonce Error! Could not reach the API ' + error);
    }
    return Promise.resolve(nonce);
}

async function verifySiwe(token: string, url: string, message: string) {
    let jwt = '';
    try {
        const body = {
            message: message,
            signature: token
        }
        const request: Request = new Request(url, {
            method: "POST",
            body: JSON.stringify(body),
        });

        const res: Response = await fetch(request);
        let data = (await res.json());
        jwt = data.jwtToken;
        console.log('verifySiwe (result)', jwt);
    } catch (error) {
        console.log('verifySiwe Error! ' + error);
    }
    return Promise.resolve(jwt);
}

function walletDisconnected(msg: string) {
    walletDisconnectedStore(msg, siweStore);
}

function walletDisconnectedStore(msg: string, siweStore: SiweStore) {
    siweStore.JWT = '';
    siweStore.token = '';
}

export function signOutAndClearJWT(reason) {
    const msg = reason || 'Session has ended';
    walletDisconnected(msg);
    sessionStorage.setItem(STORAGE_KEY_SIWE_TOKEN_UI, '');
    sessionStorage.setItem(STORAGE_KEY_JWT_UI, '');
    Sentry.setUser(null);
}

function createSiweMessage(address: string, nonce: string, statement: string) {
    const message = new SiweMessage({
        domain: store.domainForSIWE,
        address,
        statement,
        //uri: window.location.origin,
        uri: store.methodForSIWE + store.domainForSIWE,
        version: '1',
        chainId: 1,
        nonce
    });
    return message.prepareMessage();
}

function parseJwt(token) {
    try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        return {exp: 0};
    }
}
