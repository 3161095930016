// shims to connect to market data sources
// - instrument fetch (translated to expected format)
// - market data subscription (use heatmaps)
// - handler for CiQ to make chart data fetch (use v6 data)

import { reactive } from 'vue';
import { store } from './Store';
import { mapExoticTokens } from '../lib/SymbolMapping';
import short from "short-uuid"

export const CHART_HOST = 'https://charts.app.lmax.com/';
//export const CHART_HOST = 'https://chart-query-new-staging.lmaxglobal.io/';

const CHART_API_KEY = 'c80291b6f9926391e3b5c0674cb85d5c1a9b6b2aaab98384894ac8157a31749f';

const CHART_REFRESH_INTERVAL = 30*1000; // 30 seconds

// --------------------------------------------------------------------------------------------

function getRand() {
  return Math.floor(Math.random()*100000);
}

export const urls = reactive({
  chartUrl: (tokens, interval) => CHART_HOST+'chartv6/combine/digital/'+mapExoticTokens(tokens).join('+')+'/mid_ohlc/'+interval+'?chartiq=1&numbars=600&lmaxapikey='+CHART_API_KEY+'&'+getRand()
});

export const getUUIDToIdentifyUser = () => {
  var locationIdentifier = "";//[1]
  var locationSplit = location.href.split("/").reverse();
  if(locationSplit.length >= 2){
    locationIdentifier = locationSplit[1];
  }
  console.log("locationIdentifier:",locationIdentifier)
  var storageId = `UUIDToIdentifyUser`
  var uuidToIdentifyUser=localStorage.getItem(storageId);
  if(!uuidToIdentifyUser){
    uuidToIdentifyUser = `${getNewUUID()}`
  }
  localStorage.setItem(storageId, uuidToIdentifyUser);
  console.log(`UUIDToIdentifyUser:${uuidToIdentifyUser}`)
  return uuidToIdentifyUser+``;//:${locationIdentifier}`;
}

export const  getNewUUID = () => {
  const cookieTranslator = short(short.constants.cookieBase90, {
    consistentLength: false,
  }); // Use a constant for translation
  return cookieTranslator.new();
}

export const mdservices = reactive({
  async startChartData() {
    if (!store.mdStarted) {
      // load initial chart data (will need to be refreshed!)
      mdservices.loadChartData([store.frontToken, store.backToken]);
      store.chartRefreshTimer = setInterval(this.refreshChart, CHART_REFRESH_INTERVAL);
      store.mdStatus = 'Ready';
      store.mdStarted = true;
    }
  },
  async loadChartData(tokens) {
    store.chartStatus = 'Loading...';
    try {
      let useTokens = tokens || store.chartTokens;
      let url = urls.chartUrl(useTokens, store.chartInterval);
      var requestObject = {
        method: "GET",
        headers:{}
      };
      var headers = {};
      headers["Content-Type"] = "application/json";
      headers["X-Lmax-Chartid"] = getUUIDToIdentifyUser();
      headers["X-Lmax-Chartkey"] = CHART_API_KEY;
      requestObject.headers = headers;
      const res = await fetch(url,requestObject);

      store.chartData = (await res.json());
      if (tokens) {
        store.chartSymbol = tokens.join('/');
        store.chartTokens = tokens;
      }
      store.chartStatus = 'Ready';
    } catch (error) {
      store.chartStatus = 'Error! Could not reach the API ' + error
    }
  },
  refreshChart() {
    mdservices.loadChartData(null);
  }
});


