<script setup lang="ts">
import { ref ,onMounted} from "vue";
import { store } from '../store/Store';
import HistoryNarrowLayout from '../components/HistoryNarrowLayout.vue';
import PositionsNarrowLayout from "../components/PositionsNarrowLayout.vue";
const orderType = ['All', 'Instant', 'Prefunded']

const activeTabOrderPosition = ref(0);
const props = defineProps<{ title: string, extraClass: string, isDtw: boolean }>();
const selectedOrderType = ref("");
const orderTypeIsisDtw = ref(undefined)
const orderTypeList = ref([]);

const orderTypeChangeEvent = async (e) => {
  if (e.target.value == orderType[1]) {
    orderTypeIsisDtw.value = false;
  } else if (e.target.value == orderType[2]) {
    orderTypeIsisDtw.value = true;
  } else {
    orderTypeIsisDtw.value = undefined;
  }
}

onMounted(() => {
  showOrderTypeList();
});

const showOrderTypeList = () => {
  selectedOrderType.value = orderType[0]
  orderTypeList.value = Object.assign(orderType);
}

</script>

<template>
<div v-if="store.walletIsConnected" class="selectedOrderTypeClass">
      <select v-model="selectedOrderType" @change="orderTypeChangeEvent" class="alice-select">
        <option v-for="item in orderTypeList" :value="item" :key="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="alice-order-position-sub-tab">
        <o-tabs v-model="activeTabOrderPosition" type="boxed"  position="left">
            <o-tab-item :value="0" label="Orders">
            <HistoryNarrowLayout id="history-block" class="alice-history-container history-block" title="" :use-narrow-layout="store.useNarrowLayout"
                :wallet-connected="store.walletIsConnected" extra-class="" v-bind:is-dtw="orderTypeIsisDtw" />
             </o-tab-item>
            <o-tab-item :value="1" label="Positions" class="alice-order_position-sub-tab-position">
                <PositionsNarrowLayout id="positions-block" class="alice-balance-container balance-block" title="Balances"
                    :use-narrow-layout="store.useNarrowLayout" :wallet-connected="store.walletIsConnected"
                    v-bind:is-dtw="orderTypeIsisDtw" 
                    extra-class="" />
            </o-tab-item>
        </o-tabs>
    </div>
</template>

<style>

@media (max-width: 959px) {
    .alice-order-position-sub-tab .o-tabs__nav {
        position: relative;
        display: flex;
        overflow: auto;
        left: 22%;
        top: 2px;
        box-shadow: 0 0px 0px 0;
        background-color: var(--body-background-colour);
    }
    .alice-order-position-sub-tab .o-tabs__nav-item {
        background-color: var(--nav-background-colour);
    }

    .alice-order-position-sub-tab .o-tabs__content {
        width: 100%;
        padding-top: 0em;
        margin-top: 0px;
    }

    .alice-order-position-sub-tab .alice-ticket {
        margin-top: 0px;
        padding: 0px 10px 10px 10px;
        border-radius: 8px;
        box-shadow: 0 4px 18px 0 rgba(181, 181, 181, 0.5);
        border: solid 1px #f2f2f3;
    }

    .alice-order-position-sub-tab-position {
       margin:2px;
    }


  .selectedOrderTypeClass {
    position: relative;
    top: 4px;
    width: 6.25em;
    z-index: 4;
    right: 0;
    padding: 0px;
    float: right;
  }

  .selectedOrderTypeClass select {
    padding: 4px 20px 10px 12px;
    font-size: 13px;
    float: right;
  }
}
</style>