<script setup lang="ts">
import {nextTick, Ref, ref, watch} from 'vue';
import {store} from '../../store/Store';
import BigNumber from "bignumber.js";
import {validateInputForDecimal, validatePasteForDecimal} from "../../utils/commonUtils";

const props = defineProps<{ title: string, extraClass: string }>();

const focusSymbol = (target: any) => {
  nextTick(() => {
    target.select();
  });
}

const amountUpdated = (event: any) => {
  if (event.target.value != '') {
    const tolerance = event.target.value;

    if (new BigNumber(tolerance).gt(new BigNumber(10))) {
      event.target.value = new BigNumber(10).toString();
    }

    const splitOnDot = tolerance.split(".");

    if (splitOnDot.length > 1 && splitOnDot[1].length > 2) {
      event.target.value = tolerance.substring(0, tolerance.indexOf(".") + 3);
    }

    store.setSlippageTolerance(event.target.value);
  }
}

function validateTolerance(tolerance: BigNumber): BigNumber {
  if (tolerance.lt(new BigNumber(0.01))) {
    return new BigNumber(0.01);
  } else if (tolerance.gt(new BigNumber(10))) {
    return new BigNumber(10);
  } else {
    return tolerance;
  }
}

watch(() => store.buyAmount, () => {
  calcLimitAmount(new BigNumber(store.slippageTolerance));
});

function resetLimitAmount() {
  calcLimitAmount(new BigNumber("0.5"));
}

function toggleLimit() {
  store.isLimitOrder = !store.isLimitOrder;
  calcLimitAmount(new BigNumber(store.slippageTolerance));
}

function calcLimitAmount(toleranceToUse: BigNumber) {
  let limitValue: BigNumber;
  if (store.isLimitOrder) {
    let validatedTolerance: BigNumber = validateTolerance(toleranceToUse);
    store.slippageTolerance = validatedTolerance.toFixed(2, BigNumber.ROUND_FLOOR);

    const slippagePercentageMultiplierUnbounded = new BigNumber(100).minus(validatedTolerance).div(100);
    let slippagePercentageMultiplier = BigNumber.max(BigNumber.min(slippagePercentageMultiplierUnbounded, new BigNumber(1)), new BigNumber(0));
    limitValue = store.buyAmount.multipliedBy(slippagePercentageMultiplier);
  } else {
    limitValue = new BigNumber(0);
  }
  store.limitAmount = limitValue;
}

</script>

<template>
  <div :class="['container-block','container-limit-block',props.extraClass]">
    <div class="alice-limit-row">
      <div v-if="props.title" class="alice-limit-title">{{ props.title }}</div>
      <div class="alice-limit-switch">
        <o-switch :model-value="store.isLimitOrder" v-on:update:modelValue="toggleLimit"></o-switch>
      </div>
    </div>
    <div class="alice-limit-row" v-if="store.isLimitOrder">
      <div class="alice-limit-entry">
        <input :value="store.slippageTolerance"
               @focus="focusSymbol($event.target)"
               @keydown="validateInputForDecimal"
               @paste="validatePasteForDecimal"
               @keyup="amountUpdated"
               class="alice-limit-value-edit"/>&nbsp;%
      </div>
      <div class="alice-limit-buttons auto-slippage-toggle">
        <o-button rounded outlined @click="resetLimitAmount">Default</o-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-limit-block {
  width: 100%;
  height: 60px;
  margin: 20px 0 10px 0px;
  padding: 0 8px 0 12px;
}

.alice-limit-row {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
}

.alice-limit-title {
  display: inline-flex;
  font-family: WorkSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--limit-text-colour);
}

.alice-limit-switch {
  display: inline-flex;
}

.alice-limit-entry {
  display: inline-flex;
  padding: 0 10px 0 19px;
  border-radius: 28px;
  box-shadow: 0 2px 6px 0 rgba(214, 214, 214, 0.5);
  background-color: #fff;
  font-family: WorkSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: right;
}

.alice-limit-value-edit {
  color: #000000;
  border: 0;
  outline: none;
  width: 100%;
}

.alice-limit-buttons {
  display: inline-flex;
  font-family: WorkSans;
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
  margin-right: 6px;

}

.o-btn__wrapper {
  background-color: rgba(233, 244, 228, 0.2);
}
</style>