<script setup lang="ts">
import {store} from '../../store/Store';
import {mdservices} from '../../store/MarketData';

const variant = (interval) => {
  return store.chartInterval == store.intervalLookups[interval] ? undefined : 'info';
}

const setVariant = (interval) => {
  store.chartInterval = store.intervalLookups[interval];
  mdservices.loadChartData(null);
}

let intervals = store.useNarrowLayout ? store.availableIntervalsNarrow : store.availableIntervals;

</script>

<template>
  <div class="alice-button-bar">
    <o-button class="button-bar" v-for="interval in intervals" size="small" :variant="variant(interval)" :id="interval+'-chart-button'" @click="setVariant(interval)">{{ interval }}</o-button>
  </div>
</template>

<style scoped>
.alice-button-bar {
  display: inline;
  height: 27.5px;
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.o-btn {
  display: inline;
  height: 27.5px;
  width: 45px;
  margin-right: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.button-bar .o-btn__label {
  font-size: 12px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-right: 10px;
}

.o-btn--small {
  color: var(--buttonbar-background-colour);
  background-color: var(--buttonbar-faint-text-colour);
  border-color: var(--buttonbar-outline-colour);
}

.o-btn--info {
  color: var(--buttonbar-text-colour);
  background-color: var(--buttonbar-background-colour);
  border-color: var(--buttonbar-background-colour);
}
</style>
