<script setup lang="ts">
import {store} from "../../store/Store";
import {ref} from "vue";

const props = defineProps<{ title: string, includeSearch: boolean, side: string }>();
const search = ref("");
const status = ref("...");
const emit = defineEmits(['token', 'close'])

const filteredCoins = () =>
{
  const searchTerm = search.value;
  if (searchTerm.length > 0)
  {
    let foundCoins = [];
    for (let coin of store.tokenList)
    {
      let UCname = coin.name.toUpperCase();
      let UCsymbol = coin.symbol.toUpperCase();
      if (coin.symbol in store.instrumentTokens)
      {
        let UCsearch = searchTerm.toUpperCase();
        if (UCname.indexOf(UCsearch) >= 0 || UCsymbol.indexOf(UCsearch) >= 0)
        {
          foundCoins.push(coin);
        }
      }
    }
    return foundCoins;
  }
  else
  {
    let foundCoins = [];
    for (let coin of store.tokenList)
    {
      if (coin.symbol in store.instrumentTokens)
      {
        foundCoins.push(coin);
      }
    }
    return foundCoins;
  }
}

const selectCoin = (symbol) =>
{
  console.log('select', symbol);
  emit('token', symbol);
  search.value = "";
  emit('close')
}

const closeModal = () =>
{
  search.value = "";
  emit('close')
}

</script>

<template>
  <div class="alice-coin-picker-container">
    <div class="alice-ticket-title">
      <span class="align-left">
        {{ props.title }}
      </span>
      <span class="align-right">
        <o-icon title="Close" pack="fas" icon="xmark" class="alice-coin-picker-close-icon" @click="closeModal()"/>
      </span>
    </div>
    <div class="alice-ticket-rule"></div>
    <div class="alice-ticket-content">
      <div v-if="props.includeSearch" class="alice-coin-picker-search-wrapper">
        <input v-model="search" class="alice-coin-picker-search" placeholder="Search..."/>
        <o-icon pack="fas" icon="magnifying-glass" class="alice-coin-picker-search-icon"></o-icon>
      </div>
      <div class="alice-coin-picker-list">
        <div v-for="coin in filteredCoins()" :id="'alice-coin-picker-row-' + coin.symbol + '-' + side" class="alice-coin-picker-row" @click="selectCoin(coin.symbol)">
          <div class="alice-coin-picker-icon">
            <img v-bind:src="'/crypto-icons/'+coin.symbol.toLowerCase()+'.svg'"/>
          </div>
          <div class="alice-coin-picker-label">
            <div class="alice-coin-picker-symbol">{{ coin.symbol }}</div>
            <div class="alice-coin-picker-name">{{ coin.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alice-coin-picker-container {
  width: 100%;
  min-width: 240px;
  background-color: #ffffff;
}

.alice-ticket-title {
  margin-left: 20px;
  text-align: left;
  color: #000000;
}

.alice-coin-picker-close-icon {
  margin-top: 4px;
  margin-right: 4px;
  float: right;
  color: #000000;
  cursor: pointer;
}

.alice-coin-picker-close-icon:hover {
  opacity: 50%;
}

.alice-coin-picker-list {
  width: 100%;
  color: #000000;
  text-align: left;
}

.alice-coin-picker-row {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
}

.alice-coin-picker-icon {
  margin-top: 6px;
  width: 24px;
}

.alice-coin-picker-label {
  margin-left: 20px;
}

.alice-coin-picker-search-wrapper {
  margin: 0 20px 27px;
  height: 45px;
  padding: 0 20.7px 1px 19.6px;
  border-radius: 8px;
  background-color: #ffffff;
}

.alice-coin-picker-search-icon {
  color: #000000;
}

.alice-coin-picker-search {
  margin-top: 2px;
  background-color: #ffffff;
  width: 90%;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 40px;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.alice-coin-picker-search:active, .alice-coin-picker-search:focus {
  border: 0;
  outline: none;
}

.alice-coin-picker-symbol {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.alice-coin-picker-name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>
