import {store, store as storeModule} from '../store/Store';
import BigNumber from "bignumber.js";
import {ethers, utils} from "ethers";
import {setAllowance} from "./Wallet";
import {urls} from "../store/Loaders";
import {getSiweJwt} from "./SiweClient";

export interface Position
{
  currentPrice: BigNumber;
  unitsOfExposure: BigNumber;
  stake: BigNumber;
  timestamp: BigNumber;
}

// Function to add a new leverage position
export async function addLeveragePosition(
  instrumentId: number,
  stake: BigNumber,
  leverage: BigNumber
): Promise<void>
{
  const currentState = storeModule.web3Onboard.state.get();
  const wallet = currentState.wallets[0];
  const walletProvider = storeModule.getWalletProvider(wallet);
  const signer = walletProvider.getSigner();

  const chainInfo = store.getChainInfo();
  const contract = new ethers.Contract(chainInfo.leverageSmartContractAddress, chainInfo.leverageABI, signer);

  try
  {
    const stakeEncoded: ethers.BigNumber = utils.parseUnits(stake.toFixed(store.tokenDPs[store.frontToken]), store.tokenDPs[store.frontToken]);
    const leverageEncoded: ethers.BigNumber = utils.parseUnits(leverage.toFixed(2), 2);
    const tx = await contract.requestPosition(instrumentId, stakeEncoded, leverageEncoded);
    await tx.wait();
    console.log("TX:", tx.hash);
  }
  catch (e)
  {
    console.log("Caught exception will check allowance");

    console.log('>> will check allowance', store.frontToken, store.frontBalance);
    const result: boolean = await setAllowance(store.frontToken, +(store.frontBalance), chainInfo.leverageSmartContractAddress);
    if (result)
    {
      console.log("Updated allowance")
    }
    else
    {
      console.error('Allowance checking didnt work was a different TX error', (e as any));
    }
  }
}

export async function removeLeveragePosition(
    instrumentId: number
): Promise<void>
{
  const currentState = storeModule.web3Onboard.state.get();
  const wallet = currentState.wallets[0];
  const walletProvider = storeModule.getWalletProvider(wallet);
  const signer = walletProvider.getSigner();

  const chainInfo = store.getChainInfo();
  const contract = new ethers.Contract(chainInfo.leverageSmartContractAddress, chainInfo.leverageABI, signer);

  try
  {
    const tx = await contract.requestWithdrawal(instrumentId);
    await tx.wait();
    console.log("TX:", tx.hash);
  }
  catch (e)
  {
    console.log("Caught exception", e);
  }
}

export async function getLeveragePositions(): Promise<Array<{
  orderSource: string;
  versionId: string;
  positionId: number;
  traderAddress: string;
  chainInstrumentId: number;
  stake: string;
  openPrice: string;
  unitsOfExposure: string;
  buySymbol: string;
  sellSymbol: string;
}>>
{
  const chainInfo = store.getChainInfo();
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getSiweJwt()
  }
  const request: Request = new Request(urls.positionsUrl(chainInfo.label), {
    method: "GET",
    headers: headers
  });

  let positions = await fetch(request).then(res => res.json()).then(js => js.positions);
  return await Promise.all(positions.map(async pos => {
    let instrumentDictElement = store.instrumentDict["lev_" + pos.chainInstrumentId];
    return {
      ...pos,
      "stake": new BigNumber(pos.stake).toFixed(store.tokenDPs[instrumentDictElement.quoteSymbol]),
      "openPrice": new BigNumber(pos.openPrice).toFixed(store.tokenDPs[instrumentDictElement.quoteSymbol]),
      "unitsOfExposure": new BigNumber(pos.unitsOfExposure).toFixed(store.tokenDPs[instrumentDictElement.quoteSymbol]),
      "sellSymbol": instrumentDictElement.quoteSymbol,
      "buySymbol": instrumentDictElement.baseSymbol,
      "pl": (await valuePosition(pos.chainInstrumentId, store.tokenDPs[instrumentDictElement.quoteSymbol], pos.traderAddress)).minus(new BigNumber(pos.stake)).toFixed(6)
    }
  }))
}

async function valuePosition(instrumentId: number, decimalsOfValuation: number, traderAddress: string): Promise<BigNumber>
{
  const currentState = storeModule.web3Onboard.state.get();
  const wallet = currentState.wallets[0];
  const walletProvider = storeModule.getWalletProvider(wallet);
  const signer = walletProvider.getSigner();

  const chainInfo = store.getChainInfo();
  const contract = new ethers.Contract(chainInfo.leverageSmartContractAddress, chainInfo.leverageABI, signer);

  let onChainValuation = await contract.valuePosition(instrumentId, traderAddress);
  return new BigNumber(utils.formatUnits(onChainValuation, decimalsOfValuation).slice(0, 18));
}

// // Example usage:
// const whoFor = '0xYourEthereumAddress';
// const stake = BigNumber.from('1000000000000000000'); // 1 ETH in wei
// const leverage = 2;
// const pythPriceFeedId = '0xYourPythPriceFeedId';
// const decimals = 18;

// addLeveragePosition(whoFor, stake, leverage, pythPriceFeedId, decimals)
//     .then(() => console.log('Position added successfully'))
//     .catch(err => console.error('Error adding position:', err));
