<script setup lang="ts">
import { ref} from "vue";
import DepositPopup from "../elements/DepositPopup.vue";
import BigNumber from "bignumber.js";

const props = defineProps<{ title: string, extraClass: string, token: string, walletBalance: BigNumber, smartContractBalance: BigNumber }>();

const depositModalVisible = ref(false);

const openDepositPopup = () => {
  depositModalVisible.value = true;
};

</script>
<template>
  <div :class="['container-block','table_button_container',props.extraClass]">
    <div>
      <button :id="'depositBtn-' + props.token" @click="openDepositPopup" class="button">{{ props.title }}</button>
    </div>
  </div>
  <o-modal v-model:active="depositModalVisible" class="alice-swap-popup-modal" width="300px" mobileBreakpoint="960px" scroll="clip">
    <DepositPopup v-if="depositModalVisible" @close="depositModalVisible = false" :token="props.token" :walletBalance="props.walletBalance" :smartContractBalance="props.smartContractBalance"></DepositPopup>  </o-modal>
</template>

<style scoped>

</style>
