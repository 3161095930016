import {reactive} from 'vue'
import SHA256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

import {wsloaders, wsstore} from './Websocket';
import {mdservices} from './MarketData';
import domains from '../assets/domains.json';
import {providers} from "ethers";
import {pollWallets} from "../lib/Wallet";
import BigNumber from "bignumber.js";

const APP_NAME = 'Alice';
const APP_VERSION = '2.0.0';

const SEPOLIA_ETHERSCAN = 'https://sepolia.etherscan.io/tx/';
const MAINNET_ETHERSCAN = 'https://etherscan.io/tx/';

export const store = reactive({
    appname: APP_NAME,
    version: APP_VERSION,
    navType: 'boxed',
    windowWidth: window.innerWidth,
    useNarrowLayout: false,
    // backend info
    backendInfo: {},
    backendEnv: 'dev', // see domains.json
    pollFreq: 500, // see domains.json
    chainToUse: 'ETHEREUM', // see domains.json
    apiUrl: "filledInAutomatically", // see domains.json
    wssUrl: "filledInAutomatically", // see domains.json
    restApiUrl: "filledInAutomatically", // see domains.json
    // instruments
    instrumentStatus: '',
    instrumentList: [],
    instrumentDict: {},
    instrumentTokens: {},
    instrumentPairs: {},
    // market data
    mdStatus: '',
    mdStarted: false,
    // charts
    availableIntervals: ["Hour", "Day", "Week", "Month"],
    availableIntervalsNarrow: ["Hour", "Day", "Week"],
    intervalLookups: {
        "Hour": '1m',
        "Day": '15m',
        "Week": '3h',
        "Month": '1d',
        "Year": '1w'
    },
    chartInterval: '3h',
    chartStatus: '',
    chartSymbol: '',
    chartTokens: [],
    chartData: [],        // in chartiq format
    chartRefreshTimer: null,
    chartNeedsRebuild: false,
    // tokens
    tokenStatus: '...',
    tokenList: [],
    tokenDict: {},
    tokenIDs: {},
    tokenDPs: {},
    // selected tokens
    instrumentID: '',
    leverage: 1,
    maxLeverage: 1,
    leveragePositions: [],
    frontToken: '',
    frontAmount: new BigNumber(1),
    balanceToken: '',
    balanceTokenBalance: new BigNumber(0),
    balanceTokenAllowance: new BigNumber(0),
    frontBalance: new BigNumber(0),
    frontDtwBalance: new BigNumber(0),
    frontAllowance: new BigNumber(0),
    backToken: '',
    backBalance: new BigNumber(0),
    backDtwBalance: new BigNumber(0),
    sellAmount: new BigNumber(0.01),
    buyAmount: new BigNumber(0),
    limitAmount: new BigNumber(0),
    rateInvert: false,
    rate: '1',
    rateLastUpdate: 0,
    displayRate: new BigNumber(1),
    isLimitOrder: true,
    slippageTolerance: "0.50",
    // SIWE
    domainForSIWE: window.location.host,
    methodForSIWE: 'https://',
    // smart contracts
    smartContracts: {},
    tokenAddresses: {},
    // wallets
    web3Onboard: null,
    onboardPoller: null,
    onboardPollCount: 0,
    dataProvider: null,
    walletProvider: null,
    pausePolling: false,
    walletIsConnected: false,
    clientID: null,
    isWhitelisted: false,
    ethscanPrefix: MAINNET_ETHERSCAN,
    lastPlacedOrder: null,
    // METHODS
    getWalletProvider(wallet: any)
    {
        return new providers.Web3Provider(wallet.provider);
    },
    getDataProvider(wallet: any)
    {
        if (store.dataProvider == null)
        {
            if (store.backendEnv == "dev")
            {
                return new providers.JsonRpcProvider(store.getChainInfo().rpcUrl);
            }
            else
            {
                return this.getWalletProvider(wallet);
            }
        }
        else
        {
            return store.dataProvider;
        }
    },
    getChainInfo()
    {
        for (let c of (store.backendInfo as any).supportedChains)
        {
            if (c.label == store.chainToUse)
            {
                return c;
            }
        }
    },
    findInstrument()
    {
        let found = false;
        const leverageOptions: number[] = [];
        for (let instrument of store.instrumentList)
        {
            let foundNormal = (instrument.baseSymbol == store.frontToken && instrument.quoteSymbol == store.backToken);
            let foundInverted = (instrument.baseSymbol == store.backToken && instrument.quoteSymbol == store.frontToken);
            if (foundNormal || foundInverted)
            {
                store.instrumentID = instrument.instrumentId;
                store.rateInvert = foundInverted;
                found = true;
                leverageOptions.push(instrument.maxLeverage);
            }
        }
        if (found)
        {
            this.checkLeverageSettings(leverageOptions);
            wsloaders.subscribePrices(store.instrumentID);
            console.log('findInstrument: OK', store.instrumentID, store.rateInvert);
        }
        else
        {
            wsloaders.unsubscribePrices(wsstore.subscribedInstrument);
            console.error('findInstrument: NOT FOUND', store.instrumentID, store.rateInvert);
        }
        store.chartNeedsRebuild = true;
        mdservices.loadChartData([store.frontToken, store.backToken]);
    },
    checkLeverageSettings(leverageAvailableOnInstrument: number[])
    {
        const maxLeverage: number = Math.max(...leverageAvailableOnInstrument)
        if(maxLeverage > 1 && store.frontToken.startsWith("USDC"))
        {
          store.maxLeverage = maxLeverage;
        }
        else
        {
          store.maxLeverage = 1;
        }
    },
    invertPair()
    {
        console.log("INVERT: front ", store.sellAmount, " back ", store.buyAmount)
        let token: string = store.frontToken;
        store.frontToken = store.backToken;
        store.backToken = token;
        let value = store.sellAmount;

        store.sellAmount = store.buyAmount;
        store.buyAmount = value;

        // reset allowance as we dont store back allowance
        store.frontAllowance = new BigNumber(0);

        let balance = store.frontBalance;
        store.frontBalance = store.backBalance;
        store.backBalance = balance;

        let balanceDtw = store.frontDtwBalance;
        store.frontDtwBalance = store.backDtwBalance;
        store.backDtwBalance = balanceDtw;

        store.rateInvert = !store.rateInvert;
        store.rate = new BigNumber(1).div(store.rate).toFixed(9);
        store.findInstrument();
        store.chartNeedsRebuild = true;

        pollWallets();
    },
    updatePriceFromWebsocket(instrumentID: string, price: BigNumber)
    {
        if (instrumentID == store.instrumentID)
        {
            const midprice: BigNumber = store.rateInvert ? new BigNumber(1).div(price) : price;
            store.rate = midprice.toString();
            store.rateLastUpdate = Date.now();
            store.displayRate = midprice;
            this.calculateBuyAmount();
        }
        else
        {
            console.log('received price for wrong instrument', store.instrumentID, instrumentID);
        }
    },
    setSellAmount(amount: BigNumber)
    {
        store.sellAmount = amount;
        this.calculateBuyAmount();
    },
    calculateBuyAmount()
    {
        store.buyAmount = store.sellAmount.multipliedBy(new BigNumber(store.rate));
    },
    setSlippageTolerance(amount: string)
    {
        store.slippageTolerance = amount;
    }
});

export function resolveEnv()
{
    function digestMessage( nonce, message)
    {
        const hashDigest = SHA256(nonce + message);
        return Base64.stringify(hashDigest);
    }

    const host: string = location.host.split(':')[0];
    const hash = digestMessage(domains.nonce, host);
    if (hash in domains.data)
    {
        store.backendEnv = domains.data[hash].prodEnv;
        store.chainToUse = domains.data[hash].chain;
        store.pollFreq = 2500;
        if (store.backendEnv == 'demo')
        {
            store.ethscanPrefix = SEPOLIA_ETHERSCAN; // this will later be overidden by data from /info ep
        }
    }
}


