import {store} from "../store/Store";


const ERROR_NO_ENV = 'environment not found: ';
const ERROR_NO_URL = 'url not found: ';


export const API_URLS = {
    demo: {
        api: 'https://api.demo.project-alice.io/api',
        wss: 'wss://api.demo.project-alice.io/api/web-socket',
        restApi: 'https://api.demo.project-alice.io/api/v2'
    },
    prod: {
        api: 'https://api.production.project-alice.io/api',
        wss: 'wss://api.production.project-alice.io/api/web-socket',
        restApi: 'https://api.production.project-alice.io/api/v2'
    }
};


export async function addApiUrlsToStore()
{
    const env = store.backendEnv;
    let apiDetails
    if (env in API_URLS)
    {
        apiDetails = API_URLS[env]
    }
    else
    {
        if (env === "dev")
        {
            apiDetails = await fetch("/config/configuration.properties").then(res => res.json());
        }
        else
        {
            throw new Error(ERROR_NO_ENV + env);
        }
    }
    store.apiUrl = apiDetails.api;
    store.wssUrl = apiDetails.wss;
    store.restApiUrl = apiDetails.restApi;
}
