<script setup lang="ts">
import {CIQ} from 'chartiq/js/advanced'
import getLicenseKey from "chartiq/key.js";
getLicenseKey(CIQ);
//import App from '../../App.vue'
import {store} from '../../store/Store';
import {onMounted, ref, watch} from "vue";
import {calculateRangeAndPeriodicity} from "../../lib/ChartUtils";
import ButtonBar from "./ButtonBar.vue";

//const CIQ = App.CIQ;

const props = defineProps<{ type: string, extraClass: string }>();

const cardTarget = ref();
const cardIsVisible = ref(false);
const chartInitialised = ref(false);
const chartStyle = ref('mountain');
const stx = ref(null);
const changeInPeriod = ref();
const changeAsPercent = ref(0);


CIQ.ChartEngine.prototype.append("positionCrosshairsAtPointer", function () {
  if (this.currentVectorParameters.vectorType)
  {
    return;
  } // don't override if drawing

  const chart = this.chart,
      panel = chart.panel,
      yAxis = panel.yAxis,
      bar = this.barFromPixel(this.cx);

  if (this.chart.dataSegment[bar])
  {
    this.crossYActualPos = this.pixelFromPrice(this.chart.dataSegment[bar].Close, panel);
    if (yAxis.bottom < this.crossYActualPos)
    {
      this.crossYActualPos = yAxis.bottom;
    }
    this.controls.crossY.style.top = this.crossYActualPos + "px";
  }
});

onMounted(() => {
  const selectors = '#chart-block-inner-' + props.type;
  cardTarget.value = document.querySelector(selectors);
  const selectors1 = '#chart-container-block-' + props.type;
  console.log("selector " + selectors);
  console.log("selector1 " + selectors1);
  let options = {
    root: document.querySelector(selectors1),
    rootMargin: '0px',
    threshold: 0.1
  }
  let observer = new IntersectionObserver(intersection, options);
  observer.observe(cardTarget.value);
  watch(() => store.chartData, () => {
    refreshChart()
  });
})

const intersection = (entries) => {
  let wasVisible = cardIsVisible.value;
  for (let entry of entries)
  {
    if (entry.target == cardTarget.value)
    {
      cardIsVisible.value = entry.isIntersecting;
    }
  }
  if (cardIsVisible.value != wasVisible)
  {
    refreshChart();
  }
}

const analyseData = () => {
  const chartData = store.chartData;
  let lastRow = chartData[chartData.length - 1];
  let openValue = chartData[0].Open;
  let closeValue = lastRow.Close;
  let changeInPeriodLocal = +(closeValue - openValue).toFixed(2);
  let changeAsPercentLocal = +((closeValue - openValue) * 100 / openValue).toFixed(2);
  if (changeInPeriodLocal > 0)
  {
    changeInPeriod.value = '+' + changeInPeriodLocal;
  }
  else
  {
    changeInPeriod.value = changeInPeriodLocal;
  }
  if (changeAsPercentLocal != 0)
  {
    changeAsPercent.value = changeAsPercentLocal;
  }
}
const loadChart = () => {
  const getMasterData = function () {
    return [...store.chartData];
  }
  try
  {
    if (store.chartData.length > 0)
    {
      analyseData();
      if (!chartInitialised.value)
      {
        const container = document['getElementById']('chart-block-inner-' + props.type);
        const root = document;
        const config = {
          extendLastTick: true,
          layout: {
            crosshair: true,
          },
          preferences: {
            allowScroll: true,
            currentPriceLine: true,
            whitespace: 2
          },
          root,
          container
        };
        stx.value = new CIQ.ChartEngine(config);
        stx.value.chart.yAxis.displayGridLines = false;
        stx.value.chart.xAxis.displayGridLines = false;
        stx.value.chart.yAxis.drawCurrentPriceLabel = false;
        stx.value.chart.yAxis.priceFormatter = function (stx, panel, price, decimalPlaces) {
          let fmtPrice = (+price).toFixed(decimalPlaces || 6);
          return (+fmtPrice).toString();
        };
        stx.value.clearStyles();
        stx.value.setStyle("stx_mountain_chart", "border-top-color", "#9ee493");
        stx.value.setStyle("stx_mountain_chart", "background-color", "#9ee493");
        stx.value.setStyle("stx_mountain_chart", "color", "#f0f0f0");
        stx.value.setStyle("stx_mountain_chart", "width", "3px");
        stx.value.setChartType(chartStyle.value);
        chartInitialised.value = true;
      }
      let {range, periodicity} = calculateRangeAndPeriodicity(store.chartInterval);
      stx.value.loadChart(store.chartSymbol, {
            masterData: getMasterData(),
            range: range,
            periodicity: periodicity
          }, () => {
          }
      );
    }
  }
  catch (e)
  {
    console.error(e);
  }
}

const destroyChart = () => {
  if (stx.value)
  {
    stx.value.container.innerHTML = '';
    stx.value.clear();
    stx.value.destroy();
    stx.value = null;
    chartInitialised.value = false;
  }
}

const refreshChart = () => {
  if (cardIsVisible.value)
  {
    loadChart();
  }
  else
  {
    destroyChart();
  }
}

const flipChartStyle = () => {
  if (stx.value)
  {
    if (chartStyle.value == 'mountain')
    {
      chartStyle.value = 'candle';
    }
    else
    {
      chartStyle.value = 'mountain';
    }
    stx.value.setChartType(chartStyle.value);
  }
}

const invertPair = () => {
  store.invertPair();
}
</script>

<template>
  <div :ref="`chart-container-block-${props.type}`" :id="`chart-container-block-${props.type}`" :class="['container-block','container-chart',props.extraClass]">
    <div class="chart-controls">
      <span class="alice-ticket-title-front">
        <img v-bind:src="'/crypto-icons/'+store.frontToken.toLowerCase()+'.svg'" class="alice-crypto-icon-small"/>
        <img v-bind:src="'/crypto-icons/'+store.backToken.toLowerCase()+'.svg'" class="alice-crypto-icon-small alice-crypto-icon-right"/>
        {{ store.frontToken }} /
      </span>
      <span class="alice-ticket-title-back">
        {{ store.backToken }} &nbsp;
        <o-icon id="alice-chart-invert-icon" pack="fas" icon="arrow-right-arrow-left" class="alice-ticket-invert-icon" @click="invertPair()"> </o-icon>
      </span>
    </div>
    <div class="alice-chart-header">
      <div class="alice-chart-header-left">
        <div v-if="store.useNarrowLayout" class="alice-chart-header-rate">1 {{ store.frontToken }} = {{ store.displayRate.toFixed(5) }} {{ store.backToken }}</div>
      </div>
      <div class="alice-chart-header-right">
        <o-icon pack="fas" icon="chart-simple" class="alice-chart-type-icon" @click="flipChartStyle()"></o-icon>
        <ButtonBar></ButtonBar>
      </div>
    </div>
    <div :ref="`chart-block-inner-${props.type}`" :id="`chart-block-inner-${props.type}`" class="chart-block-inner">
    </div>
  </div>
</template>

<style scoped>
.container-chart {
  width: 100%;
  height: 100%;
}

.chart-title,
.chart-style {
  display: inline;
  color: #6eaa51;
  background-color: #ffffff;
}

.o-chk__check {
  color: #6eaa51;
  background-color: #ffffff;
}

.chart-block-inner {
  margin-top: 3%;
  width: 100%;
  height: 90%;
  position: relative;
}

.alice-ticket-title {
  text-align: left;
}

.chart-controls {
  font-family: WorkSans;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1f1f1f;
}

.alice-ticket-invert-icon {
  transform: translate(0, 2px);
  color: var(--chart-invert-button-colour);
  cursor: pointer;
}

.alice-chart-type-icon {
  transform: translate(0, 2px);
  margin-right: 30px;
  color: var(--nav-outline-colour);
  cursor: pointer;
}

.alice-crypto-icon-small {
  height: 24px;
  transform: translate(0, 5px);
}

.alice-crypto-icon-right {
  transform: translate(-7px, 5px);
}

.alice-chart-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: -5px;
}

.alice-chart-header-left {
  text-align: left;
}

.alice-chart-header-right {
  text-align: right;
}

.alice-chart-header-rate {
  color: #111111;
  display: inline-block;
  margin-top: 14px;
  font-size: 11px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
}

.alice-chart-header-tokens {
  color: #000000;
  display: inline-block;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

/* formats for narrow screens */
@media (max-width: 959px) {
  .chart-block-inner {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 50vh;
  }
}

@media (max-width: 420px) {
  .chart-block-inner {
    display: block;
    width: 100%;
    height: 80%;
    padding: 0px;
    margin: 0px;
    min-height: 50vh;
  }

  .alice-chart-header {
    margin-top: 0px;
    padding-top: 10px;
  }

  .alice-chart-header-right {
    margin-top: -10px;
  }

  .alice-chart-type-icon {
    display: none;
  }
}

@media (max-width: 330px) {
  .container-chart {
    height: 80%;
  }

  .chart-block-inner {
    min-height: 370px;
  }
}
</style>