<script setup lang="ts">
import HistoryHeader from './elements/HistoryHeader.vue';
import { useOrderHistoryStore } from "../store/OrderHistory";
import { computed, ref, onMounted } from "vue";
import {
  filterByOrderSource,
  filterOrderSourceByDtw,
  formatOrderForUi,
  sortByTimestampThenOrderId
} from "../utils/OrderHistoryUtils";

import { refundOrder } from "../lib/Refund";

const props = defineProps<{ title: string, extraClass: string, useNarrowLayout: boolean, walletConnected: boolean, isDtw: boolean }>();
const orderHistory = useOrderHistoryStore();

const formattedOrderHistory = computed(() => {
  return orderHistory.orders
    .toSorted(sortByTimestampThenOrderId)
    .filter(filterByOrderSource())
    .filter(filterOrderSourceByDtw(props.isDtw))
    .reverse()
    .map((order: any, i: any) => formatOrderForUi(order, i));
})


</script>
<template>


  <div :class="['container-history-panel', extraClass]" class="order-history-container">
   
    <div class="alice-ticket-content">
      <div v-for="(row, i) in formattedOrderHistory" :key="i" class="card"
        :id="'order-history-entry-' + row.positionIndex">
        <div class="card-header text-align-left">
          <div class="card-header-content">
            <img v-bind:src="'/crypto-icons/' + row.soldSymbol.toLowerCase() + '.svg'" class="card-header-img-left" />
            <img v-bind:src="'/crypto-icons/' + row.boughtSymbol.toLowerCase() + '.svg'"
              class="card-header-img-right" />
          </div>
          <div class="card-header-content-text"> {{ row.soldSymbol }}/{{ row.boughtSymbol }}</div>
        </div>
        <div class="card-body">
          <div class="text-align-left">
            <div class="row">
              <label>
                You Sold:
                <span class="font-weight-bold"> {{ row.sold }} {{ row.soldSymbol }} </span>
              </label>
              <o-button v-if="row.refundable" :id="'history-item-' + row.positionIndex + '-order-status'"
                class="card-body-settled" tag="a" @click="() => refundOrder(row)" target="_blank"
                variant="danger">REFUND
              </o-button>
              <o-button v-else :id="'history-item-' + row.positionIndex + '-order-status'" class="card-body-settled"
                tag="a" :href="row.tx_href" target="_blank" :variant="row.buttonType">{{ row.statusMessage }}
              </o-button>
            </div>
            <div class="row">
              <label>You Got:
                <span class="font-weight-bold">{{ row.received }} {{ row.boughtSymbol }}</span>
              </label>

            </div>
            <div class="row">
              <label>Limit Amount:
                <span class="font-weight-bold" :id="'history-item-' + row.positionIndex + '-limit'">{{ row.limit
                  }}</span>
              </label>
            </div>
            <div class="row">
              <label>Time:
                <span class="font-weight-bold"> {{ row.date }}</span>
              </label>
            </div>
          </div>
          <div class="card-divider">
            <div class="card-footer">
              <span v-if="row.place_tx.startsWith('0x')" :id="'history-item-' + row.positionIndex + '-place'">Place
                TxID: <a :href="row.place_tx_href" target="new">{{ row.place_tx }}</a></span>
            </div>
            <div class="card-footer">
              <span :id="'history-item-' + row.positionIndex + '-settle'" class="history-item-place"> Settle TxID: <a
                  v-if="row.settle_tx_href && row.settle_tx" :href="row.settle_tx_href" target="new">{{ row.settle_tx
                  }}</a></span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="walletConnected">
        <div v-if="formattedOrderHistory.length == 0">
          <div class="history-status">
            Awaiting your first trade
          </div>
        </div>
      </div>
      <div v-else>
        <div class="history-status">
          Please connect wallet to see transaction history
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media (max-width: 959px) {
  .order-history-container {
    position: relative;
  }

  .alice-history-header {
    padding-bottom: 10px;
  }

  .alice-ticket-content {
    margin-top: 2px;
    height: 64dvh;
    overflow: auto;
    position: relative;
  }

  .card {
    width: 100%;
    /* margin: 2.8px 0px 0px 0px; */
    border-radius: 4px;
    border: solid 2px #eaeaea;
    background-color: var(--buttonbar-background-colour);
  }

  .card-header {
    height: 2.2rem;
    display: flex;
    border-bottom: solid 1px #eaeaea;
  }


  .card-header-img-left {
    padding: 4px 2px 9px 6px;
    width: 30px;
  }

  .card-header-img-right {
    padding: 4px 2px 9px 6px;
    margin-left: -10px;
    width: 30px;
  }

  .card-header-content-text {
    padding: 4px 5px 8px 14px;
    font-size: 14px;
  }

  .card-body {
    width: 100%;
    flex-grow: 0;
    margin: 0px 0px 0px 0px;
    padding: 5px 6.4px 41px 8.6px;
    background-color: var(--card-background-colour);
  }

  .row {
    margin: 5px 2px 2px 5px;
  }

  label {
    width: 162.6px;
    flex-grow: 0;
    font-size: 13px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #000;
  }
  .card-body-settled {
    height: 24px;
    float: right;
    flex-grow: 0;
    margin: 2px 0 0px 0px;
  }


 

  .card-footer {
    width: 49%;
    padding-top: 6px;
    float: inline-end;
  }

  .card-footer span {
    flex-grow: 0;
    font-size: 11px;
  }

}
</style>