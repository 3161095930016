<script setup lang="ts">
import { store } from '../store/Store';
import { onMounted, ref, watch } from "vue";
import { OrderStatus} from "../types";

var boughtSymbol = ref("");
var popupMessage = ref("");
const notificationCloseTimer = 3000;

const showMessage = () => {
  if (store.lastPlacedOrder && store.lastPlacedOrder.instrumentId) {
    var instrumentObj = store.instrumentDict[store.lastPlacedOrder.instrumentId]
    boughtSymbol.value = store.lastPlacedOrder.side == 'SELL' ? instrumentObj.quoteSymbol : instrumentObj.baseSymbol;
    if (store.lastPlacedOrder?.instrumentId) {
      popupMessage.value = getOrderStatusMessage(store.lastPlacedOrder?.status);    
      setTimeout(() => {
        popupMessage.value = "";
      }, notificationCloseTimer);
    }
  }
}

onMounted(() => {
  popupMessage.value = "";
  watch(() => store.lastPlacedOrder, () => {
    showMessage();
  });
});

const closeModal = () => {
  popupMessage.value = "";
};

function getOrderStatusMessage(status: OrderStatus) {
  if(status && status.toUpperCase() == "PENDING" && 
    (store.lastPlacedOrder.settleTransactionId == null ||
    store.lastPlacedOrder.settleTransactionId == undefined ||
    store.lastPlacedOrder.settleTransactionId == '')){
      return "Your "+boughtSymbol.value+" order has been successfully placed!"
    }else if(status.toUpperCase() =='CANCELLED'){
      return "Your "+boughtSymbol.value+" order has been cancelled!"
    }else if(status.toUpperCase() == 'SETTLED'){
      return "Your "+boughtSymbol.value+" order has been settled!"
    }else{
      return ""
  }
}
</script>
<template>
  <div v-if="popupMessage" class="notificationRoot">
    <section v-if="store.useNarrowLayout">
      <div variant="success" closable class="toast notification-success" aria-close-label="Close notification">
        <div class="notification-close">
          <o-icon title="Close" pack="fas" icon="xmark" class="notification-close-icon" @click="closeModal()" />
        </div>
        <div class="icon flex notification-success-icon">
          <img v-bind:src="'./right-green-icon.svg'" />
        </div>
        <div class="notificationMessage">
          {{ popupMessage }}
          </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.notificationRoot {
  z-index: 100;
  width: 90%;
  margin-bottom: 0%;
  margin-bottom: 0%;
  border-radius: 10px;
  border: 0.001rem solid gray;
  background-color: rgba(255, 255, 255, 0.858);
  position: fixed;
  margin-top: -2rem;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.notification-success-icon {
    width: 30px;
    float: left;
    display: flex;
    background-color: #18ce66;
    padding: 2.8px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin-top: 2.75%;
}

.notificationMessage {
  width: 90%;
  padding-left: 2.75rem;
}

.notification-success {
  color: var(--main-text-colour-light);
  border-radius: 4px;
  margin: .5rem;
  position: relative;
  transform-origin: 50% 0;
}

.notification-close {
  float: right;
  margin-top: -3.25rem;
  margin-right: 0rem;
}

.notification-close-icon {
  color: var(--main-text-colour-light);
}
</style>