export const validateInputForDecimal = (event: any) => {
  event.target.value = event.target.value.trim();
  const allowedChars = event.target.value.includes('.') ? 
    ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'] : 
    ['.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  if (!allowedChars.includes(event.key) && !isSpecialKey(event)) {
    event.preventDefault();
  }
}

export const  validatePasteForDecimal = (event: any) => {
  const clipboardData = event.clipboardData 
  const pastedText = clipboardData.getData('text');
  if (!/^[0-9]*[.,]?[0-9]*$/.test(pastedText)) {
    event.preventDefault();
  }
}
  
const isSpecialKey = (event) => {
  // Check if it's a special key (e.g., backspace, delete, arrows)
  return event.key.length > 1 || event.ctrlKey || event.altKey || event.metaKey;
}

export const getDecimalFromDps = (input) => {
  // Convert the input number to a decimal in the form of 1 divided by 10 to the power of the input
  const decimal = 1 / Math.pow(10, input);
  // Return the decimal number as a string to maintain the format
  return decimal.toFixed(input);
}