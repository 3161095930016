<script setup lang="ts">

import {store} from '../../store/Store';
import {connectWallet} from "../../lib/Wallet";
import {ref} from "vue";

const errorText = ref('');

const connect = async () => {
  await connectWallet();
}
const providerError = (e) => {
  console.log('providerError', e);
  errorText.value = 'Wallet is not connected; please try again';
}
</script>

<template>
  <div>
    <div v-if="store.walletIsConnected"><!-- wallet is connected --></div>
    <div v-else>
      <div>
        <button id="onboardBtn" class="button_onboard" @click="connect">Connect to Wallet</button>
      </div>
      <div>{{ errorText }}</div>
    </div>
  </div>
</template>

<style scoped>
</style>