import {UiOrder} from "../types";
import {store} from "../store/Store";
import {Contract, ethers, utils} from "ethers";
import {useOrderHistoryStore} from "../store/OrderHistory";

export async function refundOrder(uiOrder: UiOrder)
{
    const orderHistory = useOrderHistoryStore();
    const order = orderHistory.orders.find((_order) => _order.orderId.eq(uiOrder.orderId));
    const currentState = store.web3Onboard.state.get()
    const wallets = currentState.wallets;
    const chainInfo = store.getChainInfo();
    if (wallets.length > 0 && chainInfo)
    {
        const signer = store.getWalletProvider(wallets[0]).getUncheckedSigner();

        const sc: Contract = new Contract(chainInfo.smartContractAddress, chainInfo.abi, signer);

        const amount: ethers.BigNumber = utils.parseUnits(order.quantitySold.toFixed(store.tokenDPs[uiOrder.soldSymbol]), store.tokenDPs[uiOrder.soldSymbol]);
        const limit: ethers.BigNumber = utils.parseUnits(order.limitAmount.toFixed(store.tokenDPs[uiOrder.boughtSymbol]), store.tokenDPs[uiOrder.boughtSymbol]);

        const address: string = await signer.getAddress();

        console.log("Refunding order: ", order.orderId, address, order.instrumentId, order.side == "BUY", amount, limit, order.timestamp);

        const refundOrderTransaction = await sc.refundOrder(order.orderId, address, order.instrumentId, order.side == "BUY", amount, limit, ethers.BigNumber.from(order.timestamp).div(1000));
        refundOrderTransaction.wait().then(() => order.status = "REFUNDED");
    }
}